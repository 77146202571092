export const nodeSizes = {
    API: { nodeWidth: 100, nodeHeight: 20 },
    TASK: { nodeWidth: 150, nodeHeight: 20 },
    PARAMETER: { nodeWidth: 150, nodeHeight: 20 },
    SECRET: { nodeWidth: 150, nodeHeight: 20 },
    CRON: { nodeWidth: 100, nodeHeight: 20 },
    SCHEDULE: { nodeWidth: 200, nodeHeight: 20 },
}

export default nodeSizes
