import React, { useRef, useState } from "react"
import { Modal, Row, Space, message } from "antd"
import { DeleteOutlined } from "@ant-design/icons"
import { useMutation, gql } from "@apollo/client"
import Markdown from "react-markdown"

import { XForm } from "../../../XForm"
import XButton from "../../../XButton"

const UPDATE_API = gql`
    mutation UpdateApiTrigger($id: Int!, $api: String!) {
        update_trigger_by_pk(pk_columns: { id: $id }, _set: { api: $api }) {
            api
            api_token
            id
        }
    }
`

const DELETE_API = gql`
    mutation UpdateApiTrigger($id: Int!) {
        delete_trigger_by_pk(id: $id) {
            id
        }
    }
`

export const ModalEditApi = (props) => {
    const { open, onClose, onUpdate, trigger, error } = props
    const [formData, setFormData] = useState(trigger)
    const [confirmLoading, setConfirmLoading] = useState(false)
    const formRef = useRef(null)

    const [updateApi] = useMutation(UPDATE_API)
    const [deleteApi] = useMutation(DELETE_API)

    const apiSchema = {
        type: "object",
        $schema: "http://json-schema.org/draft-07/schema#",
        properties: {
            api: {
                type: "string",
                description: "API Endpoint",
            },
            api_token: {
                type: "string",
                description: "API Token",
                readOnly: true,
            },
        },
        required: ["api"],
    }

    if (error) {
        message.error(error.message)
        console.log(error)
    }

    const handleUpdate = (props) => {
        setConfirmLoading(true)

        const cronFormValid = formRef.current.validateForm()
        if (cronFormValid) {
            updateApi({
                variables: {
                    id: trigger.id,
                    api: formData.api,
                },
            })
                .then(() => {
                    if (onUpdate) onUpdate()
                    message.success("Updated successfully.")
                })
                .catch((error) => {
                    message.error(error.message)
                    console.log(error)
                })
                .finally(() => {
                    onClose()
                })
        }
        setConfirmLoading(false)
    }

    const handleDelete = (props) => {
        deleteApi({
            variables: {
                id: trigger.id,
            },
        })
            .then(() => {
                message.success("Deleted successfully.")
            })
            .catch((error) => {
                message.error(error.message)
                console.log(error)
            })
            .finally(() => {
                if (onUpdate) onUpdate()
                onClose()
            })
    }

    console.log(window.location)
    // const markdown = "Trigger the API like this: ```http://" + window.location.host + "/api/rest/apiTrigger?api=<api>&api_token=<api_token>```"
    const markdown = `Trigger the API like this:\n\n \`\`\`[POST] http://${window.location.host}/api/rest/apiTrigger?api=${formData.api}&api_token=${formData.api_token}\`\`\``

    return (
        <Modal title={"Edit API Trigger"} open={open} confirmLoading={confirmLoading} okText="Submit" onOk={handleUpdate} onCancel={onClose} width={800}>
            <Row style={{ width: "100%", marginBottom: "20px" }}>
                <XForm
                    ref={formRef}
                    style={{ marginBottom: "20px" }}
                    schema={apiSchema}
                    hideButton
                    // liveValidate={true}
                    formData={formData}
                    onChange={(e) => {
                        setFormData(e.formData)
                    }}
                ></XForm>
            </Row>

            <Row style={{ width: "100%", marginBottom: "20px" }}>
                <Markdown>{markdown}</Markdown>
            </Row>

            <Row style={{ width: "100%", marginBottom: "20px" }}>
                <Space>
                    <XButton popConfirmConfig={{ title: "Are you sure you want to delete this Trigger?", onConfirm: handleDelete }} icon={<DeleteOutlined />}>
                        Delete Trigger
                    </XButton>
                </Space>
            </Row>
        </Modal>
    )
}

export default ModalEditApi
