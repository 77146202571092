import { Row, Breadcrumb } from "antd"
import React from "react"

import ResultsStoreTable from "./ResultsStoreTable"

export const ResultsStorePage = (props) => {
    return (
        <Row style={{ maxWidth: "1200px", padding: "10px" }}>
            <Row style={{ width: "100%", marginBottom: "20px" }}>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <a href="/">Home</a>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <a href="/resultsstore">Results Store</a>
                    </Breadcrumb.Item>
                </Breadcrumb>
            </Row>

            <Row justify="center" style={{ width: "100%" }}>
                <ResultsStoreTable />
            </Row>
        </Row>
    )
}
export default ResultsStorePage
