import React, { useEffect, useState } from "react"
import { Dropdown, Input } from "antd"
import { FieldTimeOutlined, CheckCircleOutlined, WarningOutlined } from "@ant-design/icons"
import cron from "cron-validate"

import AssemblyFieldLayout from "./AssemblyFieldLayout"

function AssemblyCronField(props) {
    const [validExpression, setValidExpression] = useState(false)

    useEffect(() => {
        try {
            const cronResult = cron(props?.formData)
            if (cronResult.isValid()) setValidExpression(true)
            else setValidExpression(false)
        } catch {
            setValidExpression(false)
        }
    }, [props.formData])

    const handleChange = (e) => {
        props.onChange(e.target.value)
    }

    const handleMenuClick = (e) => {
        if (e.key === "every minute") props.onChange("* * * * *")
        if (e.key === "every 15 minute") props.onChange("*/15 * * * *")
        if (e.key === "every hour") props.onChange("0 * * * *")
        if (e.key === "every day") props.onChange("0 9 * * *")
    }

    const items = [
        {
            label: "every minute",
            key: "every minute",
        },
        {
            label: "every 15 minute",
            key: "every 15 minute",
        },
        {
            label: "every hour",
            key: "every hour",
        },
        {
            label: "every day",
            key: "every day",
        },
    ]

    const menuProps = {
        items,
        onClick: handleMenuClick,
    }

    return (
        <AssemblyFieldLayout {...props}>
            <Input
                prefix={validExpression ? <CheckCircleOutlined style={{ color: "green" }} /> : <WarningOutlined style={{ color: "red" }} />}
                style={{ width: "100%" }}
                value={props.formData}
                onChange={handleChange}
                required={props.required}
                addonAfter={
                    <Dropdown menu={menuProps}>
                        <FieldTimeOutlined />
                    </Dropdown>
                }
            />
        </AssemblyFieldLayout>
    )
}

export default AssemblyCronField
