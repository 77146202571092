import { Row, Col, Typography } from "antd"
import { Handle } from "reactflow"
import React from "react"

import { theme } from "../../../../style/antd/theme"

const { Text } = Typography

const XNode = (props) => {
    const { node, tagText, input, output, subTag, headerColor, bgColor, tagColor, effectColor, shadowColor, warning = null, children, onClick, maxWidth = "100px" } = props

    const _effectColor = effectColor
    const _shadowColor = shadowColor || _effectColor
    const _headerColor = headerColor || theme["primary-color-gray"]
    const _tagColor = tagColor || _effectColor || "#ddd"
    const _bgColor = bgColor || "#353535"

    const handleCLick = (props) => {
        if (onClick) onClick(props)
    }

    const showInputHandle = input || node?.data?._io_type === "input" || node?.data?._io_type === "default"
    const showOutputHandle = output || node?.data?._io_type === "output" || node?.data?._io_type === "default"

    return (
        <div onClick={handleCLick} style={{ position: "relative" }}>
            <div
                style={{
                    position: "relative",
                    overflow: "hidden",
                    minWidth: maxWidth,
                    maxWidth: maxWidth,
                    backgroundColor: _bgColor,
                    color: "white",
                    borderRadius: "4px",
                    border: warning ? "3px solid red" : "",
                    boxShadow: `0 0 10px ${_shadowColor}`,
                }}
            >
                <Row justify="space-between" align="middle" style={{ backgroundColor: _headerColor, lineHeight: "12px", height: "18px", width: "100%" }} wrap={false}>
                    <Col flex="auto" style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
                        {tagText && (
                            <Text
                                style={{
                                    height: "18px",
                                    width: "100%",
                                    color: _tagColor,
                                    fontSize: "10px",
                                    marginLeft: "4px",
                                    marginRight: "4px",
                                }}
                            >
                                {tagText}
                            </Text>
                        )}
                    </Col>
                    <Col flex="24px">{subTag}</Col>
                </Row>
                <div
                    style={{
                        width: "100%",
                        maxWidth: maxWidth,
                        height: "100%",
                        minHeight: "20px",
                        padding: "4px",
                        backgroundColor: _bgColor,
                        fontSize: "10px",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                    }}
                >
                    {children}
                </div>
            </div>

            {showInputHandle && <Handle type="target" position="left" style={{ borderRadius: 0 }} />}
            {showOutputHandle && <Handle type="source" position="right" id={node.id} style={{ borderRadius: 0 }} />}

            {node?.selected && (
                <div
                    style={{
                        zIndex: "-1",
                        position: "absolute",
                        top: "-5px",
                        left: "-5px",
                        width: "calc(100% + 10px)",
                        height: "calc(100% + 10px)",
                        borderRadius: "5px",
                        boxShadow: `0 0 5px ${_bgColor}`,
                    }}
                ></div>
            )}
        </div>
    )
}

export default XNode
