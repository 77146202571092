import React from "react"
import { Row, Breadcrumb } from "antd"

import SecretTable from "./SecretsTable"

export const SecretsPage = (props) => {
    return (
        <Row style={{ maxWidth: "1200px", padding: "10px" }}>
            <Row style={{ width: "100%", marginBottom: "20px" }}>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <a href="/">Home</a>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <a href="/secrets">Secrets</a>
                    </Breadcrumb.Item>
                </Breadcrumb>
            </Row>

            <Row justify="center" style={{ width: "100%" }}>
                <SecretTable />
            </Row>
        </Row>
    )
}
export default SecretsPage
