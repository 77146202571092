import React, { useEffect, useState, useCallback } from "react"
import { useQuery, gql } from "@apollo/client"
import { Card, Spin, message } from "antd"
import { Bar } from "@ant-design/plots"
import { DateTime } from "luxon"
import { useInterval } from "usehooks-ts"

const GET_NODES = gql`
    query GetNode {
        node(order_by: { destroyed_at: desc_nulls_first }, limit: 20) {
            created_at
            destroyed_at
            name
        }
    }
`

const NodeOverview = () => {
    const [minTime, setMinTime] = useState(DateTime.utc().minus({ hour: 24 }))
    const [maxTime, setMaxTime] = useState(DateTime.utc())

    const [nodeData, setNodeData] = useState([])
    const { loading, error, data, refetch } = useQuery(GET_NODES)

    useEffect(() => {
        const nodes = data?.node || []
        const nodeData = nodes
            .filter((node) => {
                if (!node.destroyed_at) return 1
                if (DateTime.fromISO(node.destroyed_at) > minTime) return 1
                return 0
            })
            .sort((a, b) => {
                if (a.name < b.name) return 1
                if (a.name > b.name) return -1
                return 0
            })
            .map((node) => {
                let created = DateTime.fromISO(node.created_at)

                let destroyed = null
                if (node.destroyed_at) destroyed = DateTime.fromISO(node.destroyed_at)
                else destroyed = DateTime.utc()

                return {
                    name: node.name,
                    range: [created, destroyed],
                }
            })
        setNodeData(nodeData)
    }, [data, minTime])

    if (error) {
        message.error(error.message)
        console.log(error)
    }

    useInterval(() => {
        refetch()
            .then(() => {
                setMinTime(DateTime.utc().minus({ hour: 24 }))
                setMaxTime(DateTime.utc())
            })
            .catch((error) => {
                message.error(error.message)
                console.log(error)
            })
    }, 60000)

    const formatter = useCallback((text) => {
        // console.log(text)
        return text
        // return DateTime.fromMillis(parseFloat(text)).toLocaleString(DateTime.DATETIME_SHORT)
    }, [])

    const config = {
        data: nodeData,
        animation: false,
        xField: "range",
        yField: "name",
        autoFit: true,
        isRange: true,
        xAxis: {
            min: minTime,
            max: maxTime,
            label: {
                formatter,
            },
            type: "time",
            tickMethod: () => [minTime.toISO(), maxTime.toISO()],
        },
        tooltip: {
            formatter: (data) => {
                const start = data.range[0].toLocaleString(DateTime.DATETIME_SHORT)
                const end = data.range[1].toLocaleString(DateTime.DATETIME_SHORT)
                return { name: "", value: `${start} - ${end}` }
            },
        },
    }

    return (
        <Card title="Active Nodes Overview (24h)" size="small" style={{ marginBottom: "30px", width: "100%" }}>
            {loading && <Spin style={{ borderRadius: "10px" }} tip="Loading..." size="large" />}
            {!loading && <Bar {...config} />}
        </Card>
    )
}

export default NodeOverview
