import React, { useState } from "react"
import { Select, Tag } from "antd"

import { theme } from "../../../../style/antd/theme"
import AssemblyFieldLayout from "./AssemblyFieldLayout"

const { Option } = Select

export const AssemblyMultiSelectField = (props) => {
    // const [value, setValue] = useState(props.formData || [])
    const [value, setValue] = useState(props.formData)

    const placeholder = props.schema?.placeholder
    const value_key = props.schema?.value_key || "value"
    const label_key = props.schema?.label_key || "label"

    const renderOptions = () => {
        if (Array.isArray(props?.schema?.anyOf) && props.schema.anyOf.length >= 1) {
            return props.schema?.anyOf?.map((item, index) => (
                <Option key={item[value_key]} value={item[value_key]}>
                    {item[label_key]}
                </Option>
            ))
        } else if (Array.isArray(props?.schema?.enum) && props.schema.enum.length >= 1) {
            return props.schema?.enum?.map((item, index) => (
                <Option key={item[value_key]} value={item[value_key]}>
                    {item[label_key]}
                </Option>
            ))
        } else {
            console.log("No Options found for Multiselect.")
            return []
        }
    }

    const handleChange = (value) => {
        setValue(value)
        if (props.onChange) props.onChange(value)
    }

    function tagRender(tagprops) {
        const { label, closable, onClose } = tagprops
        const onPreventMouseDown = (event) => {
            event.preventDefault()
            event.stopPropagation()
        }
        return (
            <Tag color={props.schema?.tag_color || theme["primary-color-lavender"]} onMouseDown={onPreventMouseDown} closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
                {label}
            </Tag>
        )
    }

    return (
        <AssemblyFieldLayout {...props}>
            <Select mode={"multiple"} allowClear placeholder={placeholder} style={{ width: "100%" }} value={value} onChange={handleChange} tagRender={tagRender}>
                {renderOptions()}
            </Select>
        </AssemblyFieldLayout>
    )
}

export default AssemblyMultiSelectField
