import React from "react";
import { Row, Col, Button } from 'antd';
import {
    MinusSquareOutlined,
    PlusSquareOutlined
} from "@ant-design/icons"

import AssemblyFieldLayout from "./AssemblyFieldLayout";


const AddButton = (props) => {
    return (
        <Button {...props} type="primary" icon={<PlusSquareOutlined />}>
            Add Item
        </Button>
    );
}


function RemoveButton(props) {
    return (
        <Button {...props} icon={<MinusSquareOutlined />} type="link" >
        </Button>
    );
}


function DefaultArrayItem(props) {
    return (
        <Row wrap={false} key={props.key} align="middle" style={{ marginBottom: "5px" }}>
            <Col flex="auto">{props.children}</Col>
            <Col flex="40px" style={{ textAlign: "center" }}>
                <RemoveButton style={{ marginLeft: "0px", marginRight: "0px" }}
                    disabled={props.disabled || props.readonly}
                    onClick={props.onDropIndexClick(props.index)}
                />
            </Col>
        </Row>
    );
}

function AssemblyArrayField(props) {

    const addButton = (
        <React.Fragment>
            {props.canAdd && (
                <Row justify="end" align="middle">
                    <AddButton
                        className="array-item-add"
                        onClick={props.onAddClick}
                        disabled={props.disabled || props.readonly}
                    />
                </Row>
            )}
        </React.Fragment>
    )

    const compact = Array.isArray(props.items) && props.items.length === 0

    return (
        <React.Fragment>
            <AssemblyFieldLayout {...props} label={props.title}>
                {props.items && props.items.map(p => DefaultArrayItem(p))}
                {compact && addButton}
            </AssemblyFieldLayout>

            {!compact && addButton}
        </React.Fragment>
    )
}

export default AssemblyArrayField;