import { Row } from "antd";
import React from "react";

export const UserManagementPage = (props) => {
    return (
        <Row justify="center" style={{ maxWidth: "1200px" }}>
            UserManagementPage
        </Row>
    )
}
export default UserManagementPage