import React from "react"
import { Row, Col, Collapse, Typography } from "antd"

import AssemblyFlow from "../../../common/XFlow/AssemblyFlow"

const { Title, Text } = Typography
const { Panel } = Collapse

export const FlowOverview = (props) => {
    const { flow, isAggregation, style } = props

    return (
        <div style={{ marginBottom: "20px", ...style }}>
            {isAggregation && <Title level={5}>Aggregation Flow:</Title>}
            {!isAggregation && <Title level={5}>Flow:</Title>}

            <Row wrap={false} style={{ paddingTop: "4px", paddingBottom: "4px" }}>
                <Col flex={"170px"} align={"left"}>
                    <Text>Name</Text>
                </Col>
                <Col flex="auto" style={{ minWidth: "280px" }}>
                    <Text>{flow.name}</Text>
                </Col>
            </Row>

            {flow?.description && (
                <Row wrap={false} style={{ paddingTop: "4px", paddingBottom: "4px" }}>
                    <Col flex={"170px"} align={"left"}>
                        <Text>Description</Text>
                    </Col>

                    <Col flex="auto" style={{ minWidth: "280px" }}>
                        <p>{flow.description}</p>
                    </Col>
                </Row>
            )}

            <Collapse defaultActiveKey={[]} ghost>
                <Panel header="Show Graph" key="3">
                    <Row style={{ height: "320px", width: "100%" }}>
                        <AssemblyFlow flow={flow} isAggregation={isAggregation} />
                    </Row>
                </Panel>
            </Collapse>
        </div>
    )
}
