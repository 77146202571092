import React, { useEffect, useRef, useState } from "react"
import { Modal, Row, Space, Typography, message, Switch } from "antd"
import { DeleteOutlined } from "@ant-design/icons"
import { useQuery, useMutation, gql } from "@apollo/client"

import { FlowOverview } from "../../../../models/flow/FlowOverview"
import { XForm } from "../../../XForm"
import XButton from "../../../XButton"
import JsonEdit from "../../../XForm/InputComponents/JsonEdit"

const { Title } = Typography

const GET_SCHEDULE_DATA = gql`
    query GetScheduleData($id: uuid!) {
        flow_schedule_by_pk(id: $id) {
            flow_id
            name
            parameters
            aggregation_parameters
            description
            is_aggregating
            aggregation_channels
            upstream_chain_triggers {
                id
                upstream_flow_schedule_id
                downstream_flow_schedule_id
                upstream_flow_schedule {
                    id
                    flow {
                        flow_settings
                    }
                }
            }
            flow {
                id
                name
                description
                gitlink
                created_at
                updated_at
                project_id
                flow_settings
                aggregation_flow_settings
                project {
                    flows {
                        id
                        flow_settings
                        aggregation_flow_settings
                        flow_schedules {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
`

const DELETE_SCHEDULE = gql`
    mutation DeleteSchedule($id: uuid!) {
        delete_flow_schedule_by_pk(id: $id) {
            id
        }
    }
`

const UPDATE_SCHEDULE = gql`
    mutation MyMutation($id: uuid = "", $flowschedule: flow_schedule_set_input = {}) {
        update_flow_schedule_by_pk(pk_columns: { id: $id }, _set: $flowschedule) {
            id
        }
    }
`

export const ModalEditSchedule = (props) => {
    const { open, onClose, onUpdate, scheduleId } = props
    const [confirmLoading, setConfirmLoading] = useState(false)

    const { loading, error, data, refetch } = useQuery(GET_SCHEDULE_DATA, { variables: { id: scheduleId } })

    const [deleteSchedule] = useMutation(DELETE_SCHEDULE)
    const [updateSchedule] = useMutation(UPDATE_SCHEDULE)
    const schedule = data?.flow_schedule_by_pk

    const flow = schedule?.flow
    const flowSettings = flow?.flow_settings
    const aggregationFlowSettings = flow?.aggregation_flow_settings

    const scheduleFormRef = useRef(null)
    const [scheduleFormData, setScheduleFormData] = useState(null)
    const [showJsonParameters, setShowJsonParameters] = useState(false)

    const parameterFormRef = useRef(null)
    const [parameterFormData, setParameterFormData] = useState(null)
    const parameterSchema = flowSettings?.parameter_schema

    const aggregationParameterFormRef = useRef(null)
    const [aggregationParameterFormData, setAggregationParameterFormData] = useState(null)
    const aggregationParameterSchema = aggregationFlowSettings?.parameter_schema

    const hasParameters = parameterSchema?.properties && Object.keys(parameterSchema?.properties).length > 0
    const hasAggregationParameters = aggregationParameterSchema?.properties && Object.keys(aggregationParameterSchema?.properties).length > 0

    if (error) {
        console.log(error)
        message.error(error.message)
    }

    useEffect(() => {
        if (schedule) {
            setScheduleFormData({
                name: schedule?.name,
                description: schedule?.description || "",
            })

            setParameterFormData(schedule?.parameters)
            setAggregationParameterFormData(schedule?.aggregation_parameters)
        }
    }, [schedule])

    const scheduleSchema = {
        type: "object",
        $schema: "http://json-schema.org/draft-07/schema#",
        properties: {
            name: {
                type: "string",
            },
            description: {
                type: "string",
            },
        },
        required: ["name"],
    }

    const handleOk = (props) => {
        setConfirmLoading(true)

        const scheduleFormValid = scheduleFormRef.current.validateForm()
        const parameterFormValid = parameterFormRef?.current && parameterFormRef.current.validateForm()

        if (scheduleFormValid && parameterFormValid) {
            updateSchedule({
                variables: {
                    id: scheduleId,
                    flowschedule: {
                        ...scheduleFormData,
                        parameters: parameterFormData,
                        aggregation_parameters: aggregationParameterFormData,
                    },
                },
            })
                .then(() => {
                    message.success("Schedule updated successfully.")
                    refetch()
                })
                .catch((error) => {
                    message.error(error.message)
                })
                .finally(() => {
                    if (onUpdate) onUpdate()
                    onClose()
                })
        }
        setConfirmLoading(false)
    }

    const handleDeleteSchedule = (props) => {
        setConfirmLoading(true)
        deleteSchedule({ variables: { id: scheduleId } })
            .then(() => {
                message.success("Schedule deleted successfully.")
            })
            .catch((error) => {
                message.error(error.message)
            })
            .finally(() => {
                if (onUpdate) onUpdate()
                onClose()
            })
        setConfirmLoading(false)
    }

    const handleJsonParameterChange = (newFormData) => {
        if (newFormData.parameters) setParameterFormData(newFormData.parameters)
        if (newFormData.aggregation_parameters) setAggregationParameterFormData(newFormData.aggregation_parameters)
    }

    return (
        <Modal
            title={"Edit Schedule"}
            open={open}
            confirmLoading={confirmLoading}
            okText="Submit"
            onOk={handleOk}
            okButtonProps={{
                loading: loading,
            }}
            onCancel={onClose}
            width={800}
        >
            {loading && <div>loading ...</div>}
            {schedule && scheduleFormData && (
                <div style={{ width: "100%" }}>
                    <Title level={5}>Schedule:</Title>
                    <Row style={{ width: "100%", marginBottom: "20px" }}>
                        <XForm
                            ref={scheduleFormRef}
                            style={{ marginBottom: "20px", width: "100%" }}
                            schema={scheduleSchema}
                            hideButton
                            liveValidate={false}
                            formData={scheduleFormData}
                            onChange={(e) => {
                                setScheduleFormData(e.formData)
                            }}
                        ></XForm>
                    </Row>

                    <FlowOverview flow={flow} />

                    <Row style={{ width: "100%", marginBottom: "20px" }}>
                        {(hasParameters || hasAggregationParameters) && (
                            <>
                                <Title level={5}>Parameters</Title>
                                <Switch
                                    checkedChildren="json"
                                    unCheckedChildren="form"
                                    style={{ marginLeft: "10px" }}
                                    checked={showJsonParameters}
                                    onChange={(checked) => setShowJsonParameters(checked)}
                                />
                            </>
                        )}
                        <div style={{ width: "100%", marginBottom: "20px" }}>
                            {showJsonParameters ? (
                                <JsonEdit formData={{ parameters: parameterFormData, aggregation_parameters: aggregationParameterFormData }} onChange={handleJsonParameterChange} />
                            ) : (
                                <>
                                    {hasParameters && (
                                        <XForm
                                            ref={parameterFormRef}
                                            style={{ marginBottom: "20px" }}
                                            schema={flowSettings?.parameter_schema}
                                            hideButton
                                            liveValidate={true}
                                            formData={parameterFormData}
                                            onChange={(e) => {
                                                setParameterFormData(e.formData)
                                            }}
                                        />
                                    )}

                                    {hasAggregationParameters && (
                                        <XForm
                                            ref={aggregationParameterFormRef}
                                            style={{ marginBottom: "20px" }}
                                            schema={aggregationFlowSettings?.parameter_schema}
                                            hideButton
                                            liveValidate={true}
                                            formData={aggregationParameterFormData}
                                            onChange={(e) => {
                                                setAggregationParameterFormData(e.formData)
                                            }}
                                        />
                                    )}
                                </>
                            )}
                        </div>
                    </Row>

                    <Row style={{ width: "100%", marginBottom: "20px" }}>
                        <Space>
                            <XButton popConfirmConfig={{ title: "Are you sure you want to delete this Schedule?", onConfirm: handleDeleteSchedule }} icon={<DeleteOutlined />}>
                                Delete Schedule
                            </XButton>
                        </Space>
                    </Row>
                </div>
            )}
        </Modal>
    )
}

export default ModalEditSchedule
