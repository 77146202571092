import React, { useState } from "react"
import { Button, message, Tooltip } from "antd"
import { DownloadOutlined } from "@ant-design/icons"
import { useMutation, gql } from "@apollo/client"

import downloadFile from "../../../utils/downloadFile"

const GET_BLOB_SAS_URL = gql`
    mutation MyMutation($blobUrl: String!) {
        getResultSASUrl(blobUrl: $blobUrl) {
            blobSASUrl
        }
    }
`

const BlobDownloadButton = ({ blobLocation, tooltipConfig, ...btnProps }) => {
    const [getBlobSas] = useMutation(GET_BLOB_SAS_URL)
    const [loading, setLoading] = useState(false)

    const btnDisabled = !blobLocation

    const handleDownload = () => {
        setLoading(true)
        getBlobSas({ variables: { blobUrl: blobLocation } })
            .then((response) => {
                return response.data.getResultSASUrl.blobSASUrl
            })
            .then((blosSasUrl) => {
                console.log(blosSasUrl)
                return downloadFile(blosSasUrl)
            })
            .then((response) => {
                message.success(response)
            })
            .catch((error) => {
                message.error(error.message)
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const btn = <Button icon={<DownloadOutlined />} loading={loading} disabled={btnDisabled} onClick={handleDownload} {...btnProps} />
    if (tooltipConfig && tooltipConfig.title) {
        return (
            <Tooltip placement={tooltipConfig.placement || "top"} title={tooltipConfig.title}>
                {btn}
            </Tooltip>
        )
    }
    return btn
}

export default BlobDownloadButton
