import React from "react"
import { Table, Space, Button } from "antd"
import { SyncOutlined } from "@ant-design/icons"
import { SearchOutlined } from "@ant-design/icons"
import SearchFilter from "./SearchFilter"

function getNestedProperty(obj, keys) {
    if (!Array.isArray(keys)) {
        keys = [keys]
    }
    return keys.reduce((accumulator, currentKey) => {
        return accumulator && accumulator[currentKey] !== undefined ? accumulator[currentKey] : undefined
    }, obj)
}

export const XTable = (props) => {
    const { columns, dataSource, loading, onDataRefresh, ...tableProps } = props

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: SearchFilter,
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "primary" : undefined }} />,
        onFilter: (value, record) => {
            let dataIndexValue = getNestedProperty(record, dataIndex)
            return dataIndexValue ? dataIndexValue.toString().toLowerCase().includes(value.toLowerCase()) : false
        },
    })

    if (columns == null) return <div>columns not provided</div>

    const advancedColumns = columns.map((c) => {
        let tmpc = { ...c }

        // Sort
        if (c.sort === true && c.sorter === undefined) {
            tmpc.sorter = (a, b) => {
                const dataIndexValueA = getNestedProperty(a, tmpc.dataIndex)
                const dataIndexValueB = getNestedProperty(b, tmpc.dataIndex)

                if (dataIndexValueA === undefined || dataIndexValueB === undefined) return 0
                if (typeof dataIndexValueA === "string") return dataIndexValueA.localeCompare(dataIndexValueB)
                if (typeof dataIndexValueA === "number") return dataIndexValueA - dataIndexValueB
                return 0
            }
        }

        // Filter
        if (c.filter === true && c.filters === undefined) {
            if (dataSource) {
                let uniqueValues = new Set()
                dataSource.forEach((obj) => {
                    const prop = getNestedProperty(obj, c.dataIndex)
                    if (Array.isArray(prop)) {
                        prop.forEach((e) => uniqueValues.add(e))
                    } else {
                        uniqueValues.add(prop)
                    }
                })
                const uniqueValuesList = [...uniqueValues]

                tmpc.filters = uniqueValuesList
                    .filter((value) => {
                        if (value && (typeof value === "string" || typeof value === "number")) return true
                        return false
                    })
                    .map((value) => {
                        return { text: value, value: value }
                    })

                if (c.onFilter === undefined) {
                    tmpc.onFilter = (value, record) => {
                        const prop = getNestedProperty(record, c.dataIndex)
                        if (Array.isArray(prop)) {
                            if (value && typeof value === "string") return prop.includes(value)
                        } else {
                            if (value && typeof value === "string") return value.localeCompare(getNestedProperty(record, c.dataIndex)) === 0
                        }

                        return value === getNestedProperty(record, c.dataIndex)
                    }
                }
            }
        }
        // if (c.filteredValue === undefined) tmpc.filteredValue = null

        // Search
        if (c.search === true) {
            return { ...tmpc, ...getColumnSearchProps(c.dataIndex) }
        }
        return { ...tmpc }
    })

    return (
        <div>
            {typeof onDataRefresh === "function" && (
                <Space style={{ marginBottom: "4px" }}>
                    <Button type="primary" icon={<SyncOutlined spin={loading} />} onClick={onDataRefresh}>
                        Refresh
                    </Button>
                </Space>
            )}
            <Table {...tableProps} loading={loading} tableLayout={"fixed"} dataSource={dataSource} columns={advancedColumns} />
        </div>
    )
}

export default XTable
