import React, { useEffect, useState } from "react"

import { ApolloProvider } from "@apollo/client"
import { useApolloClient } from "./useApolloClient"
import { InteractionStatus, InteractionRequiredAuthError } from "@azure/msal-browser"

import { useMsal } from "@azure/msal-react"
import { loginRequest } from "../auth/msal/auth-config"

import { Client } from "@microsoft/microsoft-graph-client"

const ApolloConnection = ({ children }) => {
    const { instance, accounts, inProgress } = useMsal()
    const [accessToken, setAccessToken] = useState(null)
    const [idToken, setIdToken] = useState(null)
    const [usedToken, setUsedToken] = useState(null)
    const [apolloClient, setApolloClient] = useApolloClient(idToken)

    useEffect(() => {
        const intervalId = setInterval(() => {
            acquireIdToken()
        }, 300000) // 5 minutes in milliseconds

        return () => clearInterval(intervalId)
    })

    useEffect(() => {
        console.log("MSAL status changed, running acquireIdToken")
        acquireIdToken()
    }, [instance, accounts, inProgress])

    const checkSetUsedToken = (token) => {
        if (token && token !== usedToken) {
            console.log("[ApolloConnection] new token -> apollo reload")
            setApolloClient(token)
            setUsedToken(token)
            const expirationTime = instance.getAccountByHomeId(accounts[0].homeAccountId)?.idTokenClaims?.exp * 1000
            console.log("The token expires on", new Date(expirationTime))
        }
    }

    useEffect(() => {
        async function getProfilePicture() {
            const client = Client.init({
                authProvider: (done) => {
                    done(null, accessToken)
                },
            })

            try {
                const photo = await client.api("/me/photo/$value").get()
                const profileImage = window.URL.createObjectURL(photo)
                console.log(profileImage)
                localStorage.setItem("profileImage", profileImage)
            } catch (error) {
                console.log(error)
                localStorage.setItem("profileImage", null)
            }
        }
        if (accessToken) getProfilePicture()
    }, [accessToken])

    const acquireIdToken = () => {
        if (inProgress === InteractionStatus.None) {
            const accessTokenRequest = {
                scopes: loginRequest.scopes,
                account: accounts[0],
            }
                instance
                    .acquireTokenSilent(accessTokenRequest)
                    .then((accessTokenResponse) => {
                        setIdToken(accessTokenResponse.idToken)
                        setAccessToken(accessTokenResponse.accessToken)
                        console.log("[ApolloConnection] Token refreshed silently")
                        console.log(accessTokenResponse.idToken)
                        checkSetUsedToken(accessTokenResponse.idToken)
                    })
                    .catch((error) => {
                        if (error instanceof InteractionRequiredAuthError) {
                            instance
                                .acquireTokenPopup(accessTokenRequest)
                                .then((accessTokenResponse) => {
                                    setIdToken(accessTokenResponse.idToken)
                                    setAccessToken(accessTokenResponse.accessToken)
                                    console.log("[ApolloConnection] Token refreshed interactivly")
                                    checkSetUsedToken(accessTokenResponse.idToken)
                                })
                                .catch((error) => {
                                    console.log(error)
                                })
                        }
                        console.log(error)
                    })
        }
    }

    console.log(apolloClient)
    if (apolloClient) {
        return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
    }

    return <div>loading</div>
}

export default ApolloConnection
