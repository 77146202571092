import { Modal, Typography, Row, Switch, Space, Tag, message } from "antd"
import React, { useState, useRef, useEffect } from "react"
import { useMutation, useQuery, gql } from "@apollo/client"
import { DateTime } from "luxon"
import { XForm } from "../XForm"
import { theme } from "../../../style/antd/theme"

import { useInputOptions } from "../../../utils/inputOptions"
import JsonEdit from "../XForm/InputComponents/JsonEdit"

const { Title } = Typography

const GET_FLOWS_BY_PK = gql`
    query GetFlowById($id: uuid = "") {
        flow_by_pk(id: $id) {
            id
            name
            description
            tags
            gitlink
            flow_settings
            aggregation_flow_settings
            created_at
            updated_at
            project_id
            project {
                id
                name
                library
            }
        }
    }
`

const CREATE_SCHEDULE = gql`
    mutation CreateSchedule($flow_schedule: flow_schedule_insert_input!) {
        insert_flow_schedule_one(object: $flow_schedule) {
            id
        }
    }
`

const RUN_FLOW = gql`
    mutation RunFlow($object: flow_run_insert_input = {}) {
        insert_flow_run_one(object: $object) {
            id
        }
    }
`

export const ModalScheduleFlow = (props) => {
    const { onClose, flowId } = props

    const { loading, error, data } = useQuery(GET_FLOWS_BY_PK, { variables: { id: flowId } })
    const [createSchedule] = useMutation(CREATE_SCHEDULE)
    const [runFlow] = useMutation(RUN_FLOW)

    const flow = data?.flow_by_pk
    const flowSettings = flow?.flow_settings
    const aggregationFlowSettings = flow?.aggregation_flow_settings
    const scheduleIsAggregating = aggregationFlowSettings ? true : false

    const inputOptionResponse = useInputOptions()
    const inputOptions = inputOptionResponse.inputOptions

    const [switchScheduled, setswitchScheduled] = useState(props.open)
    const [extraFormProperties, setExtraFormProperties] = useState({})

    const scheduleFormRef = useRef(null)
    const [scheduleFormData, setScheduleFormData] = useState(null)

    const parameterFormRef = useRef(null)
    const [parameterFormData, setParameterFormData] = useState(null)
    const [showJsonParameters, setShowJsonParameters] = useState(false)
    const parameterSchema = flowSettings?.parameter_schema

    const aggregationParameterFormRef = useRef(null)
    const [aggregationParameterFormData, setAggregationParameterFormData] = useState(null)
    const aggregationParameterSchema = aggregationFlowSettings?.parameter_schema

    const [confirmLoading, setConfirmLoading] = useState(false)

    const hasParameters = parameterSchema?.properties && Object.keys(parameterSchema?.properties).length > 0
    const hasAggregationParameters = aggregationParameterSchema?.properties && Object.keys(aggregationParameterSchema?.properties).length > 0

    useEffect(() => {
        const newExtraProperties = {}

        if (scheduleIsAggregating) {
            newExtraProperties.aggregationChannels = {
                type: "string",
                subtype: "input",
                title: "Aggregation Channel",
            }
        }

        setExtraFormProperties(newExtraProperties)
    }, [flowSettings, inputOptions, scheduleFormData, scheduleIsAggregating])

    if (error) {
        message.error(error.message)
        console.log(error)
    }

    const scheduleSchema = {
        type: "object",
        $schema: "http://json-schema.org/draft-07/schema#",
        properties: {
            name: {
                type: "string",
                description: "Give this Schedule a descriptive Name.",
            },
            description: {
                type: "string",
                description: "If you want to describe the use of this Schedule, go ahead.",
            },
            ...extraFormProperties,
        },
        required: ["name"],
    }

    const handleRun = () => {
        setConfirmLoading(true)

        const parameterFormValid = !!parameterFormData?.current ? parameterFormRef.current.validateForm() : true

        if (parameterFormValid) {
            const formData = {
                flow_id: flow.id,
                schedule_time: DateTime.utc(),
                state: "Scheduled",
                state_details: { message: "Manual run." },
                parameters: parameterFormData,
            }
            runFlow({
                variables: {
                    object: formData,
                },
            })
                .then((response) => {
                    console.log("Started Manual run: ", response)
                    onClose()
                })
                .catch((error) => {
                    console.error(error)
                })
        }
    }

    const handleOk = () => {
        setConfirmLoading(true)

        const parameterFormValid = !!parameterFormData?.current ? parameterFormRef.current.validateForm() : true
        const scheduleFormValid = !!scheduleFormRef?.current ? scheduleFormRef.current.validateForm() : true

        if (scheduleFormValid && parameterFormValid) {
            const flowScheduleInput = {
                name: scheduleFormData.name,
                description: scheduleFormData.description,
                parameters: parameterFormData,
                flow_id: flow.id,
                is_aggregating: scheduleIsAggregating,
                aggregation_parameters: aggregationParameterFormData,
                aggregation_channels: scheduleFormData.aggregationChannels,
            }

            createSchedule({
                variables: {
                    flow_schedule: flowScheduleInput,
                },
            })
                .then(() => {
                    message.success("Schedule " + flow.name + " created successfully.")
                    onClose()
                })
                .catch((error) => {
                    message.error(error.message)
                    console.log(error)
                })
        }

        setConfirmLoading(false)
    }

    const handleJsonParameterChange = (newFormData) => {
        if (newFormData.parameters) setParameterFormData(newFormData.parameters)
        if (newFormData.aggregation_parameters) setAggregationParameterFormData(newFormData.aggregation_parameters)
    }

    return (
        <Modal
            title={
                <Space style={{ display: "flex", justifyContent: "space-between", alignItems: "right" }}>
                    {switchScheduled ? "Create Schedule: " + flow?.name : "Create Run: " + flow?.name}
                    <Space>
                        <Switch
                            style={{
                                marginRight: "20px",
                                cursor: "pointer",
                                borderRadius: "1.6rem",
                                background: switchScheduled ? theme["primary-color-blue"] : theme["secondary-color"],
                                transition: "all .4sease",
                            }}
                            checked={switchScheduled}
                            onChange={setswitchScheduled}
                            checkedChildren="Create Schedule"
                            unCheckedChildren="Create Direct Run"
                        />
                    </Space>
                </Space>
            }
            style={{zIndex: 1000}}
            open={props.open}
            confirmLoading={confirmLoading}
            okText={switchScheduled ? "Create Schedule" : "Start Run"}
            onOk={switchScheduled ? handleOk : handleRun}
            onCancel={onClose}
            cancelButtonProps={{
                loading: loading,
                shape: "round",
            }}
            okButtonProps={{
                loading: loading,
                shape: "round",
                style: {
                    backgroundColor: switchScheduled ? theme["primary-color"] : theme["secondary-color"],
                    borderColor: switchScheduled ? theme["primary-color"] : theme["secondary-color"],
                },
            }}
            width={800}
        >
            {switchScheduled && (
                <Row style={{ width: "100%", marginBottom: "20px" }}>
                    <Title level={5}>Schedule Settings</Title>
                    {scheduleIsAggregating && (
                        <div style={{ width: "100%", marginBottom: "10px" }}>
                            <Tag color="purple">is aggregating</Tag>
                        </div>
                    )}
                    <XForm
                        ref={scheduleFormRef}
                        style={{ marginBottom: "20px" }}
                        schema={scheduleSchema}
                        hideButton
                        liveValidate={true}
                        formData={scheduleFormData}
                        onChange={(e) => {
                            setScheduleFormData(e.formData)
                        }}
                    ></XForm>
                </Row>
            )}

            <Row style={{ width: "100%", marginBottom: "20px" }}>
                {(hasParameters || hasAggregationParameters) && (
                    <>
                        <Title level={5}>Parameters</Title>
                        <Switch checkedChildren="json" unCheckedChildren="form" style={{ marginLeft: "10px" }} checked={showJsonParameters} onChange={(checked) => setShowJsonParameters(checked)} />
                    </>
                )}
                <div style={{ width: "100%", marginBottom: "20px" }}>
                    {showJsonParameters ? (
                        <JsonEdit formData={{ parameters: parameterFormData, aggregation_parameters: aggregationParameterFormData }} onChange={handleJsonParameterChange} />
                    ) : (
                        <>
                            {hasParameters && (
                                <XForm
                                    ref={parameterFormRef}
                                    style={{ marginBottom: "20px" }}
                                    schema={flowSettings?.parameter_schema}
                                    hideButton
                                    liveValidate={true}
                                    formData={parameterFormData}
                                    onChange={(e) => {
                                        setParameterFormData(e.formData)
                                    }}
                                ></XForm>
                            )}
                            {hasAggregationParameters && (
                                <XForm
                                    ref={aggregationParameterFormRef}
                                    style={{ marginBottom: "20px" }}
                                    schema={aggregationFlowSettings?.parameter_schema}
                                    hideButton
                                    liveValidate={true}
                                    formData={aggregationParameterFormData}
                                    onChange={(e) => {
                                        setAggregationParameterFormData(e.formData)
                                    }}
                                ></XForm>
                            )}
                        </>
                    )}
                </div>
            </Row>
        </Modal>
    )
}

export default ModalScheduleFlow
