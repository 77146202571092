import { Collapse, Switch } from "antd"
import { useState } from "react"
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons"

import AssemblyFieldLayout from "./AssemblyFieldLayout.js"
import { theme } from "../../../../style/antd/theme.js"
import JsonEdit from "../InputComponents/JsonEdit"

function AssemblyEmptyObjectFieldTemplate(props) {
    const [jsonEdit, setJsonEdit] = useState(false)

    const handleSwitchJson = (checked, event) => {
        event.stopPropagation()
        setJsonEdit(checked)
    }

    const panelHeader = <Switch checked={jsonEdit} checkedChildren="json" unCheckedChildren="form" onChange={handleSwitchJson} />

    const expandIcon = (props) => {
        if (props.isActive) return <CaretUpOutlined style={{ fontSize: "20px", color: theme["primary-color-lavender-dark"] }} />
        return <CaretDownOutlined style={{ fontSize: "20px", color: theme["primary-color-lavender-dark"] }} />
    }

    return (
        <AssemblyFieldLayout {...props}>
            <Collapse style={{ width: "100%" }} size="small" defaultActiveKey={["1"]} ghost={false} bordered={true} expandIconPosition={"start"} expandIcon={expandIcon}>
                <Collapse.Panel style={{ flexGrow: "1" }} header={panelHeader} key="1">
                    {jsonEdit && <JsonEdit {...props} readOnly={props.disabled} />}
                    {!jsonEdit && props.children}
                </Collapse.Panel>
            </Collapse>
        </AssemblyFieldLayout>
    )
}

export default AssemblyEmptyObjectFieldTemplate
