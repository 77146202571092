import React, { useState } from "react"
import { Button, Tooltip, Popconfirm } from "antd"

export const XButton = (props) => {
    const { tooltipConfig, popConfirmConfig, modalConfig, onClick, ...btnProps } = props
    const Modal = modalConfig?.Modal
    const modalProps = modalConfig?.props

    const [modalOpen, setModalOpen] = useState(false)

    const handleBtnClick = (e) => {
        if (Modal) setModalOpen(true)
        if (onClick) onClick(e)
        e.stopPropagation()
    }

    const onCancel = (e) => {
        if (popConfirmConfig?.onCancel) popConfirmConfig?.onCancel(e)
        e.stopPropagation()
    }

    let btn = <Button {...btnProps} onClick={handleBtnClick}></Button>

    if (tooltipConfig && tooltipConfig?.title) {
        btn = (
            <Tooltip placement={tooltipConfig?.placement || "top"} title={tooltipConfig?.title}>
                {btn}
            </Tooltip>
        )
    }

    if (popConfirmConfig && popConfirmConfig?.title) {
        btn = (
            <Popconfirm
                placement={popConfirmConfig?.placement || "top"}
                title={popConfirmConfig?.title}
                onConfirm={popConfirmConfig?.onConfirm}
                onCancel={onCancel}
                okText={popConfirmConfig?.okText || "Yes"}
                cancelText={popConfirmConfig?.cancelText || "No"}
            >
                {btn}
            </Popconfirm>
        )
    }

    if (Modal && modalOpen)
        return (
            <div>
                {btn}
                <Modal
                    {...modalProps}
                    open={modalOpen}
                    onClose={() => {
                        setModalOpen(false)
                        if (modalProps.onClose) modalProps.onClose()
                    }}
                ></Modal>
            </div>
        )
    return btn
}

export default XButton
