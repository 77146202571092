import React from "react"
import { Space, Typography, message } from "antd"
import { useQuery, gql } from "@apollo/client"
import { DateTime } from "luxon"
import { useInterval } from "usehooks-ts"

import HashPattern from "../../common/HashPattern"
import { RunStateTag } from "../../common/StateTags"
import ButtonDeleteFlowRun from "../../models/flow_runs/ButtonDeleteFlowRun"
import ButtonFlowRunDetails from "../../models/flow_runs/ButtonFlowRunDetails"
import XTable from "../../common/XTable"

const { Text } = Typography

const GET_FLOW_RUNS = gql`
    query GetFlowRuns($flow_id: uuid!) {
        flow_run(where: { flow_id: { _eq: $flow_id } }, order_by: { schedule_time: desc_nulls_last }, limit: 10) {
            id
            prior_run_id
            state
            schedule_time
            start_time
            parameters
            state_details
            end_time
            flow_schedule {
                name
                id
            }
        }
    }
`

export const FlowRunTable = (props) => {
    const { flowId, expanded, ...tableProps } = props
    const { loading, error, data, refetch } = useQuery(GET_FLOW_RUNS, { variables: { flow_id: flowId } })

    if (error) {
        message.error(error.message)
        console.log(error)
    }

    useInterval(
        () => {
            refetch({ flow_id: flowId }).catch((error) => {
                message.error(error.message)
                console.log(error)
            })
        },
        expanded ? 5000 : null
    )

    const flowRuns = data?.flow_run

    const columns = [
        {
            title: "ID",
            dataIndex: ["id"],
            key: "id",
            width: "40px",
            render: (text, record, index) => {
                return (
                    <Space>
                        <HashPattern tooltipConfig={{ title: record.id }} text={record.id} copy={record.id} size={12}></HashPattern>
                    </Space>
                )
            },
        },
        {
            title: "Schedule Time",
            dataIndex: "schedule_time",
            key: "schedule_time",
            sort: true,
            filter: true,
            search: true,
            render: (text, record, index) => {
                return <Text style={{ fontSize: "10px" }}>{text ? DateTime.fromISO(text).toLocaleString(DateTime.DATETIME_MED) : ""}</Text>
            },
        },
        {
            title: "Start Time",
            dataIndex: "start_time",
            key: "start_time",
            sort: true,
            filter: true,
            search: true,
            render: (text, record, index) => {
                return <Text style={{ fontSize: "10px" }}>{text ? DateTime.fromISO(text).toLocaleString(DateTime.DATETIME_MED) : ""}</Text>
            },
        },
        {
            title: "End Time",
            dataIndex: "end_time",
            key: "end_time",
            sort: true,
            filter: true,
            search: true,
            render: (text, record, index) => {
                return <Text style={{ fontSize: "10px" }}>{text ? DateTime.fromISO(text).toLocaleString(DateTime.DATETIME_MED) : ""}</Text>
            },
        },
        {
            title: "State",
            dataIndex: "state",
            key: "state",
            sort: true,
            filter: true,
            search: true,
            render: (text, record, index) => {
                return <RunStateTag state={text} />
            },
        },
        {
            title: "Actions",
            key: "action",
            sorter: true,
            render: (text, record, index) => (
                <Space size="small">
                    <ButtonFlowRunDetails id={record.id} />
                    <ButtonDeleteFlowRun id={record.id} />
                </Space>
            ),
        },
    ]

    return <XTable size="small" pagination={{ pageSize: 10, size: "small", hideOnSinglePage: true }} {...tableProps} columns={columns} loading={loading} dataSource={flowRuns} rowKey="id"></XTable>
}

export default FlowRunTable
