import React, { useEffect, useState } from "react"
import { Col, Row, message } from "antd"
import { theme } from "../../../style/antd/theme"
import { useQuery, gql } from "@apollo/client"
import { useInterval } from "usehooks-ts"
import { DateTime } from "luxon"

import JobsDuration from "./JobsDuration"
import ScheduleDuration from "./ScheduleDuration"
import InfoCard from "./InfoCard"
import NodeOverview from "./NodeOverview"

const GET_RUNS = gql`
    query GetFlowRuns($created_gte: timestamptz!) {
        flow_run(
            order_by: { schedule_time: asc }
            where: { created_at: { _gte: $created_gte }, state: { _in: ["Failed", "Success", "Manually Scheduled", "Scheduled", "Running", "Aggregation Initialized", "Aggregation Pending"] } }
        ) {
            id
            state
            schedule_time
            start_time
            state_details
            end_time
            created_at
            flow {
                id
                name
            }
            flow_schedule {
                name
                id
            }
        }
    }
`

export const HomePage = (props) => {
    const [time] = useState(DateTime.utc().minus({ days: 1 }).toISO())
    const [flowRuns, setFlowRuns] = useState([])
    const [chartData, setChartData] = useState([])
    const [numScheduled, setNumScheduled] = useState(null)
    const [numFailed, setNumFailed] = useState(null)
    const [numSuccess, setNumSuccess] = useState(null)
    const { loading, error, data, refetch } = useQuery(GET_RUNS, { variables: { created_gte: time } })

    if (error) {
        message.error(error.message)
        console.log(error)
    }

    useEffect(() => {
        const newFlowRuns = data?.flow_run
        if (Array.isArray(newFlowRuns)) {
            const mostRecentFlowRun = newFlowRuns.slice(-1)[0]
            const prevMostRecentFlowRun = flowRuns.slice(-1)[0]

            if (mostRecentFlowRun?.schedule_time !== prevMostRecentFlowRun?.schedule_time) {
                setFlowRuns(newFlowRuns)
            }
        }
    }, [data, flowRuns])

    useEffect(() => {
        let newChartData = []

        flowRuns.forEach((flowRun) => {
            const scheduleTime = flowRun.schedule_time ? new Date(flowRun.schedule_time) : null
            const starttime = flowRun.start_time ? new Date(flowRun.start_time) : null
            const endtime = flowRun.end_time ? new Date(flowRun.end_time) : null
            const flowName = flowRun?.flow?.name

            if (scheduleTime && starttime && endtime && endtime > starttime) {
                newChartData.push({
                    id: flowRun.flow_id,
                    scheduleTime: scheduleTime.toISOString(),
                    starttime: starttime.toISOString(),
                    duration: endtime - starttime,
                    flowName: flowName,
                })
            }
        })

        setChartData(newChartData)
        setNumScheduled(flowRuns.filter((run, index) => ["Scheduled", "Deployed", "Manually Scheduled", "Aggregation Initialized", "Aggregation Pending"].includes(run.state)).length)
        setNumFailed(flowRuns.filter((run, index) => ["Failed", "Outdated"].includes(run.state)).length)
        setNumSuccess(flowRuns.filter((run, index) => run.state === "Success").length)
    }, [flowRuns])

    useInterval(() => {
        const time = DateTime.utc().minus({ days: 1 }).toISO()
        refetch({ created_gte: time }).catch((error) => {
            message.error(error.message)
        })
    }, 60000)

    return (
        <Row style={{ maxWidth: "1200px", padding: "10px" }}>
            <Row gutter={[16, 16]} style={{ width: "100%" }}>
                <Col span={8}>
                    <InfoCard title="Successfull Runs" content={numSuccess} loading={loading} color={theme["success"]}></InfoCard>
                </Col>
                <Col span={8}>
                    <InfoCard title="Scheduled Runs" content={numScheduled} loading={loading} color={theme["scheduled"]}></InfoCard>
                </Col>
                <Col span={8}>
                    <InfoCard title="Failed Runs" content={numFailed} loading={loading} color={theme["failed"]}></InfoCard>
                </Col>
                <Col span={12}>
                    <JobsDuration jobsDurationData={chartData}></JobsDuration>
                </Col>
                <Col span={12}>
                    <ScheduleDuration scheduleDurationData={chartData}></ScheduleDuration>
                </Col>
                <Col span={24}>
                    <NodeOverview />
                </Col>
            </Row>
        </Row>
    )
}
export default HomePage
