import React from "react"
import { Card, Spin } from "antd"
import { Scatter } from "@ant-design/plots"

const JobsDuration = ({ jobsDurationData, loading }) => {
    const jobsDurationConfig = {
        animate: false,
        data: jobsDurationData,
        meta: {
            year: {
                type: "time",
            },
        },
        xField: "starttime",
        yField: "duration",
        seriesField: "flowName",
        colorField: "flowName",
        size: 8,
        shape: "circle",
        style: {
            height: "300px",
        },
        yAxis: {
            min: 0,
            label: {
                formatter: (v) => `${(v / 1000).toFixed(0)} s`,
            },
        },
        xAxis: {
            // type: "time",
            label: {
                autoRotate: false,
                formatter: (v) =>
                    new Date(v).toLocaleTimeString([], {
                        hour: "numeric",
                        minute: "numeric",
                    }),
            },
        },
        slider: {
            start: 0.0,
            end: 1.0,
        },
        legend: {
            position: "top",
            flipPage: true,
            maxRow: 2,
            selected: {},
        },
        color: ["#A155B9", "#9CE6E9", "#DC828F", "#F4C815"],
    }

    return (
        <Card title="Job Duration (24h): " size="small">
            {loading && <Spin style={{ borderRadius: "10px" }} tip="Loading..." size="large" />}
            {!loading && <Scatter {...jobsDurationConfig} />}
        </Card>
    )
}

export default JobsDuration
