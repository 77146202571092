import { Row, Breadcrumb } from "antd"
import React from "react"

import NewProjectFlow from "../../common/XFlow/NewProjectFlow"

export const DebugPage = (props) => {
    return (
        <Row style={{ maxWidth: "1200px", padding: "10px" }}>
            <Row style={{ width: "100%", marginBottom: "20px" }}>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <a href="/">Home</a>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <a href="/debug">Debug</a>
                    </Breadcrumb.Item>
                </Breadcrumb>
            </Row>

            <Row justify="first" style={{ width: "100%" }}>
                <div style={{ height: "500px", width: "100%" }}>
                    <NewProjectFlow></NewProjectFlow>
                </div>
            </Row>
        </Row>
    )
}
export default DebugPage
