import { Layout, Row, Col } from "antd";
import React from "react";

import { UserSettings } from "./UserSettings";

const { Header } = Layout

export const TopBar = (props) => {
    
    return (
        <Header style={{ padding: "0 10px" }}>
            <Row wrap={false}>
                <Col flex="auto">

                </Col>
                <Col flex="200px">
                    <UserSettings></UserSettings>
                </Col>

            </Row>
        </Header>
    )
}
export default TopBar;