import React, { useState } from "react"
import { useNavigate } from "react-router-dom"

import { Typography, Space, message } from "antd"
import { EditOutlined, DeleteOutlined, FileSearchOutlined } from "@ant-design/icons"
import { useQuery, useMutation, gql } from "@apollo/client"
import { useInterval } from "usehooks-ts"

import { ModalResultsCreateEdit } from "./ModalResultsCreateEdit"
import XTable from "../../common/XTable"
import XButton from "../../common/XButton"

const { Text } = Typography

const GET_IDENTIFIER = gql`
    query GetIdentifier {
        resultstore_identifier {
            description
            identifier
        }
    }
`

const DELETE_IDENTIFIER = gql`
    mutation DeleteIdentifier($identifier: String = "") {
        delete_resultstore_identifier_by_pk(identifier: $identifier) {
            identifier
            description
        }
    }
`

export const ResultsStoreTable = (props) => {
    const [modalOpen, setModalOpen] = useState(false)
    const [modalData, setModalData] = useState(null)
    const { loading, error, data, refetch } = useQuery(GET_IDENTIFIER)
    const [deleteIdentifier] = useMutation(DELETE_IDENTIFIER)
    const navigate = useNavigate()

    const identifier = data?.resultstore_identifier

    if (error) {
        message.error(error.message)
        console.log(error)
    }

    useInterval(() => {
        refetch().catch((error) => {
            message.error(error.message)
            console.log(error)
        })
    }, 60000)

    const handleDeleteIdentifier = (identifier) => {
        deleteIdentifier({ variables: { identifier: identifier } })
            .catch((error) => {
                message.error(error.message)
                console.log(error)
            })
            .then(() => {
                message.success(`"${identifier}" deleted successfully!`)
                refetch()
            })
    }

    const handleGoToFiles = (identifier) => {
        navigate(`/resultsstore/${identifier}`)
    }

    const columns = [
        {
            title: "Name",
            dataIndex: "identifier",
            key: "identifier",
            filter: true,
            search: true,
            render: (text, record, index) => {
                return <Text>{text}</Text>
            },
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
            render: (text, record, index) => {
                return <Text>{text}</Text>
            },
        },
        {
            title: "Actions",
            key: "action",
            sorter: true,
            render: (text, record, index) => (
                <Space size="small">
                    <XButton
                        tooltipConfig={{ title: "Edit Identifier" }}
                        onClick={() => {
                            setModalData(record)
                            setModalOpen(true)
                        }}
                        type="primary"
                        icon={<EditOutlined />}
                    />
                    <XButton
                        tooltipConfig={{ title: "Delete Identifier" }}
                        popConfirmConfig={{
                            title: `Are you sure you want to delete "${record.identifier}?"`,
                            onConfirm: () => {
                                handleDeleteIdentifier(record.identifier)
                            },
                        }}
                        type="primary"
                        icon={<DeleteOutlined />}
                    ></XButton>
                    <XButton tooltipConfig={{ title: "See Files" }} type="primary" icon={<FileSearchOutlined />} onClick={() => handleGoToFiles(record.identifier)}></XButton>
                </Space>
            ),
        },
    ]

    return (
        <div>
            <Space style={{ width: "100%", marginBottom: "10px" }}>
                <XButton
                    type="primary"
                    onClick={() => {
                        setModalData(null)
                        setModalOpen(true)
                    }}
                >
                    Create Identifier
                </XButton>
            </Space>

            <XTable columns={columns} loading={loading} dataSource={identifier} rowKey="identifier"></XTable>

            <ModalResultsCreateEdit
                open={modalOpen}
                identifier={modalData}
                onClose={() => {
                    setModalData(null)
                    setModalOpen(false)
                    refetch()
                }}
            />
        </div>
    )
}

export default ResultsStoreTable
