import React from "react";
import { InputNumber } from 'antd';

import AssemblyFieldLayout from "./AssemblyFieldLayout";


function AssemblyNumberField(props) {

    const handleChange = (newVal) => {
        props.onChange(newVal)
    }

    return (
        <AssemblyFieldLayout {...props}>
            <InputNumber style={{ width: "100%" }} value={props.formData} onChange={handleChange} />
        </AssemblyFieldLayout>
    );
}

export default AssemblyNumberField;