import React from "react";
import { Row, Breadcrumb } from "antd";
import FlowTable from "./FlowTable";


export const FlowsPage = (props) => {

    return (
        <Row style={{ maxWidth: "1200px", padding: "10px" }}>
            <Row style={{ width: "100%", marginBottom: "20px" }}>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <a href="/">Home</a>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <a href="/flows">Flows</a>
                    </Breadcrumb.Item>
                </Breadcrumb>
            </Row>


            {/* <Row justify="center" style={{ width: "100%" }}>
                <Title>Flows Page</Title>
            </Row> */}

            <Row justify="center" style={{ width: "100%" }}>
                <FlowTable></FlowTable>
            </Row>
        </Row>
    )
}
export default FlowsPage