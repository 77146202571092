import React from "react"
import { useParams, useNavigate } from "react-router-dom"
import { Row, Col, Breadcrumb, message } from "antd"
import { gql, useSubscription } from "@apollo/client"

import ModalRunDetails from "./ModalRunDetails"
import PanelRuns from "./PanelRuns"

const GET_FLOW_RUNS = gql`
    subscription MySubscription {
        flow_run(order_by: { schedule_time: desc_nulls_last }, where: { is_child_run: { _eq: false } }, limit: 200) {
            id
            schedule_time
            start_time
            end_time
            parameters
            prior_run_id
            state
            state_details
            updated_at
            created_at
            parent_run_id
            flow_schedule_id
            flow_schedule {
                name
                id
            }
            flow_id
            flow {
                id
                name
            }
            is_aggregation_run
            is_child_run
        }
    }
`

export const RunsPage = (props) => {
    const { id } = useParams()
    const navigate = useNavigate()

    const closeModal = () => {
        navigate("/runs/")
    }

    const { loading, error, data } = useSubscription(GET_FLOW_RUNS)

    if (error) {
        message.error(error.message)
        console.log(error.message)
    }

    const flowRun = data?.flow_run || []

    const openStates = ["Manually Scheduled", "Initialized", "Deployed", "User Rerun", "Scheduled", "Running", "Aggregation Initialized", "Aggregation Pending", "Aggregation Scheduled"]
    const openRuns = flowRun.filter((run) => openStates.includes(run.state))

    const failedStates = ["Failed", "Outdated"]
    const failedRuns = flowRun.filter((run) => failedStates.includes(run.state))

    const successStates = ["Success", "Canceled"]
    const successRuns = flowRun.filter((run) => successStates.includes(run.state))

    return (
        <Row style={{ padding: "10px" }}>
            <Row style={{ width: "100%", marginBottom: "20px" }}>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <a href="/">Home</a>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <a href="/runs">Runs</a>
                    </Breadcrumb.Item>
                </Breadcrumb>
            </Row>

            <Row justify="start" gutter={[12, 12]} style={{ width: "100%", padding: "0px" }}>
                <Col xl={24} xxl={24}>
                    <PanelRuns hideReRunBtn={true} title="Open FlowRuns" flowRuns={openRuns} loading={loading}></PanelRuns>
                </Col>
                <Col xl={24} xxl={24}>
                    <PanelRuns hideReRunBtn={false} title="Failed FlowRuns" flowRuns={failedRuns} loading={loading}></PanelRuns>
                </Col>
                <Col xl={24} xxl={24}>
                    <PanelRuns hideReRunBtn={true} title="Successfull FlowRuns" flowRuns={successRuns} loading={loading}></PanelRuns>
                </Col>
            </Row>

            {id && <ModalRunDetails open={true} flowRunId={id} onClose={closeModal} />}
        </Row>
    )
}
export default RunsPage
