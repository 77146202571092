import { Row, Breadcrumb } from "antd"
import React from "react"
import NewProjectFlow from "../../common/XFlow/NewProjectFlow"

export const SchedulesPage = (props) => {
    return (
        <Row style={{ maxWidth: "1200px", padding: "10px" }}>
            <Row style={{ width: "100%", marginBottom: "20px" }}>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <a href="/">Home</a>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <a href="/schedules">Schedules</a>
                    </Breadcrumb.Item>
                </Breadcrumb>
            </Row>

            {/* Show Project Schedules*/}
            <Row justify="first" style={{ width: "100%", minHeight: "800px" }}>
                <NewProjectFlow></NewProjectFlow>
            </Row>
        </Row>
    )
}

export default SchedulesPage
