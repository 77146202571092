import React from "react"

import { Row, Button } from "antd"
import { LoginOutlined } from "@ant-design/icons"

import { IAVParticles } from "./IAVParticles"

export const LogoutPage = (props) => {
    const login = props.login
    return (
        <Row align="middle" justify="center" style={{ width: "100%", height: "100%", position: "relative" }}>
            <IAVParticles />

            <Button
                onClick={login}
                icon={<LoginOutlined />}
                type="primary"
                size="large"
                shape="round"
                style={{
                    top: "50vh",
                }}
            >
                LOG IN
            </Button>
        </Row>
    )
}

export default LogoutPage
