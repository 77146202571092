import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { Modal, Typography, Row, Space, Col, Tooltip, Collapse, Spin, message } from "antd"
import { gql, useSubscription } from "@apollo/client"
import { DateTime } from "luxon"

import XTable from "../../common/XTable"
import XCopyClipboard from "../../common/XCopy"
import AssemblyFlow from "../../common/XFlow/AssemblyFlow"
import ChildRunTable from "./ChildRunTable"

import { NodeCollapseOutlined, CopyOutlined } from "@ant-design/icons"
import { theme } from "../../../style/antd/theme"
import XButton from "../../common/XButton"
import JsonEdit from "../../common/XForm/InputComponents/JsonEdit"

const { Text } = Typography
const { Panel } = Collapse

const GET_FLOW_RUN = gql`
    subscription MySubscription($flow_run_id: uuid!) {
        flow_run_by_pk(id: $flow_run_id) {
            id
            is_aggregation_run
            parameters
            parent_run_id
            prior_run_id
            flow_schedule_id
            state
            state_details
            flow_schedule {
                name
            }
            flow {
                id
                name
                description
                gitlink
                created_at
                updated_at
                project_id
                flow_settings
                aggregation_flow_settings
                project {
                    id
                    name
                }
            }
        }
    }
`

const GET_FLOW_RUN_LOGS = gql`
    subscription GetFlowRunLogs($flow_run_id: uuid!) {
        flow_run_log(where: { flow_run_id: { _eq: $flow_run_id } }) {
            id
            level
            message
            name
            timestamp
            task_run_id
            task_run {
                name
            }
            details
        }
    }
`

const GET_TASK_RUNS = gql`
    subscription MySubscription($flow_run_id: uuid!) {
        task_run(where: { flow_run_id: { _eq: $flow_run_id } }) {
            id
            name
            result
            slug
            state
            state_details
            updated_at
        }
    }
`
const CollapseParameters = ({ formData, readonly }) => {
    return (
        <Collapse ghost>
            <Panel header={<span style={{ fontWeight: "bold" }}>Parameters</span>} key="parameters">
                <JsonEdit formData={formData} readonly={readonly} />
            </Panel>
        </Collapse>
    )
}

const CollapseInfo = ({ info }) => {
    return (
        <Collapse ghost>
            <Panel header={<span style={{ fontWeight: "bold" }}>General Infos</span>} key="scheduledetails">
                <div style={{ marginLeft: "30px", marginBottom: "20px" }}>
                    <div>
                        <strong>Schedule Name:</strong> {info.scheduleName}
                    </div>
                    <div>
                        <strong>Schedule ID:</strong> {info.scheduleId}
                        <XCopyClipboard text={info.scheduleId} icon={<CopyOutlined />} cpyColor={theme["secondary-color"]} cpyBorderColor="white" />
                    </div>
                </div>
            </Panel>
        </Collapse>
    )
}

const CollapseChildRuns = ({ flowRun }) => {
    if (!flowRun.is_aggregation_run) return <div></div>

    return (
        <Collapse ghost>
            <Panel header={<span style={{ fontWeight: "bold" }}>Child Runs</span>} key="childruns">
                <ChildRunTable flowRun={flowRun}></ChildRunTable>
            </Panel>
        </Collapse>
    )
}

export const ModalRunDetails = (props) => {
    const { open, onClose, flowRunId } = props
    const [filteredInfo, setFilteredInfo] = useState({})
    const [confirmLoading, setConfirmLoading] = useState(false)

    const navigate = useNavigate()

    const flowRunLogsQuery = useSubscription(GET_FLOW_RUN_LOGS, { variables: { flow_run_id: flowRunId } })
    const flowRunQuery = useSubscription(GET_FLOW_RUN, { variables: { flow_run_id: flowRunId } })
    const taskRunQuery = useSubscription(GET_TASK_RUNS, { variables: { flow_run_id: flowRunId } })

    const flowRun = flowRunQuery?.data?.flow_run_by_pk
    const flow = flowRun?.flow
    const scheduleId = flowRun?.flow_schedule_id
    const scheduleName = flowRun?.flow_schedule?.name
    const parentRunId = flowRun?.parent_run_id
    const priorId = flowRun?.prior_run_id
    const flowRunParameters = flowRun?.parameters

    const flowRunLogs = flowRunLogsQuery?.data?.flow_run_log

    const taskRuns = taskRunQuery?.data?.task_run

    const flowRunParametersJSON = JSON.stringify(flowRunParameters, null, 2)

    const info = {
        scheduleName: scheduleName,
        scheduleId: scheduleId,
    }

    if (flowRunQuery.error) {
        message.error(flowRunQuery.error.message)
        console.log(flowRunQuery.error)
    }
    if (taskRunQuery.error) {
        message.error(taskRunQuery.error.message)
        console.log(taskRunQuery.error)
    }

    const handleOk = () => {
        setConfirmLoading(true)
        setConfirmLoading(false)
        onClose()
    }

    const filterChange = (filters) => {
        setFilteredInfo(filters)
    }

    const handleNodeClick = (e, node) => {
        console.log(node, node.data.name)
        if (node && node.data.name) {
            setFilteredInfo({ task_run_name: [node.data.name] })
        }
    }

    const smallLoadingSpinner = (
        <Row justify="center" align="middle" style={{ minHeight: "100px" }}>
            <Col>
                <Spin style={{ borderRadius: "10px" }} tip="Loading..." size="small" />
            </Col>
        </Row>
    )

    const columns = [
        {
            title: "Time",
            dataIndex: "timestamp",
            key: "timestamp",
            sort: true,
            filteredValue: filteredInfo?.time,
            width: "140px",
            render: (text, record, index) => {
                return <Text style={{ fontSize: "11px" }}>{text ? DateTime.fromISO(text).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS) : ""}</Text>
            },
        },
        {
            title: "Level",
            dataIndex: "level",
            key: "level",
            filter: true,
            filteredValue: filteredInfo?.level,
            width: "80px",
            render: (text, record, index) => {
                return <Text style={{ fontSize: "11px" }}>{text}</Text>
            },
        },
        {
            title: "Task",
            dataIndex: ["task_run", "name"],
            key: "task_run_name",
            filter: true,
            filteredValue: filteredInfo?.task_run_name,
            width: "120px",
            render: (text, record, index) => {
                return <Text style={{ fontSize: "11px" }}>{text}</Text>
            },
        },
        {
            title: "Message",
            dataIndex: "message",
            key: "message",
            filter: true,
            filteredValue: filteredInfo?.message,
            search: true,
            render: (text, record, index) => {
                return <Text style={{ fontSize: "11px", whiteSpace: "pre-wrap" }}>{text}</Text>
            },
        },
    ]

    return (
        <Modal
            title={
                <Space style={{ display: "flex" }}>
                    {"FlowRun: " + flowRunId}
                    <Tooltip title={"Copy Flow ID"}>
                        <XCopyClipboard icon={<CopyOutlined />} text={flowRunId} cpyColor={theme["secondary-color"]} cpyBorderColor="white" />
                    </Tooltip>

                    {parentRunId ? (
                        <XButton type="primary" size="small" onClick={() => parentRunId && navigate(`/runs/${parentRunId}`)}>
                            Parent Run
                        </XButton>
                    ) : null}

                    {priorId && (
                        <Tooltip title={"Previous Run"}>
                            <XButton
                                icon={<NodeCollapseOutlined />}
                                style={{
                                    boxShadow: "none",
                                    borderColor: "white",
                                    color: theme["primary-color-lavender-dark"],
                                }}
                                onClick={priorId && navigate(`/runs/${priorId}`)}
                            ></XButton>
                        </Tooltip>
                    )}
                </Space>
            }
            open={open}
            confirmLoading={confirmLoading}
            okText="Submit"
            onOk={handleOk}
            onCancel={onClose}
            width={1000}
        >
            {!flowRunQuery.loading ? (
                <>
                    {/* FLOW*/}
                    <Collapse ghost defaultActiveKey="assembly">
                        <Panel header={<span style={{ fontWeight: "bold", padding: "0px", margin: "0px" }}>Flow: {flow?.name}</span>} key="assembly" style={{ padding: "0px", margin: "0px" }}>
                            <AssemblyFlow flow={flow} showTaskStates={true} taskRuns={taskRuns} loading={flowRunQuery?.loading || taskRunQuery?.loading} onNodeClick={handleNodeClick}></AssemblyFlow>
                        </Panel>
                    </Collapse>

                    {/* PARAMETERS */}
                    {flowRunParameters && typeof flowRunParameters === "object" && Object.keys(flowRunParameters).length > 0 && flowRunParametersJSON && (
                        <CollapseParameters formData={flowRunParameters} readonly={true} />
                    )}

                    {/* ChildRuns*/}
                    <CollapseChildRuns flowRun={flowRun} />

                    {/* INFO */}
                    <CollapseInfo info={info} />
                    {/* FLOW LOGS */}
                    <Row style={{ marginBottom: "10px" }}>
                        <XTable rowKey="id" columns={columns} dataSource={flowRunLogs} loading={flowRunQuery.loading} size="small" onChange={filterChange}></XTable>
                    </Row>
                </>
            ) : (
                smallLoadingSpinner
            )}
        </Modal>
    )
}

export default ModalRunDetails
