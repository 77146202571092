import { Tooltip, message } from "antd"
import { generate } from "geopattern"

export const HashPattern = (props) => {
    const { tooltipConfig, text, size, copy } = props
    const _size = size || 20
    const backgroundImage = generate(text).toDataUrl()

    const handleCopyClipboard = (e) => {
        if (copy) {
            try {
                navigator.clipboard.writeText(copy)
                message.success(`Copied successfully! ${copy}`)
            } catch (err) {
                message.error("Copy not successful!")
            }
        }

        if (props.onClick) props.onClick(e)
    }

    const pattern = <div style={{ width: `${_size}px`, height: `${_size}px`, backgroundImage, cursor: copy ? "pointer" : "default" }} onClick={handleCopyClipboard}></div>

    if (tooltipConfig && tooltipConfig.title) {
        const tooltipWidth = tooltipConfig.width
        return (
            <Tooltip placement={tooltipConfig.placement || "top"} title={tooltipConfig.title} overlayStyle={{ maxWidth: tooltipWidth }}>
                {pattern}
            </Tooltip>
        )
    } else {
        return { pattern }
    }
}

export default HashPattern
