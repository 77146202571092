import { Modal, Row, message } from "antd"
import React, { useState, useRef, useEffect } from "react"
import { useMutation, gql } from "@apollo/client"

import { XForm } from "../../common/XForm"

const CREATE_SECRET = gql`
    mutation CreateSecret($key: String!, $value: String!) {
        insert_secret_one(object: { key: $key, value: $value }, on_conflict: { constraint: secrets_key_key, update_columns: [value, updated_at] }) {
            id
        }
    }
`

export const ModalSecretCreateEdit = (props) => {
    const { open, onClose, secret } = props
    const [createSecret] = useMutation(CREATE_SECRET)
    const secretFormRef = useRef(null)
    const [secretFormData, setSecretFormData] = useState(secret)
    const [confirmLoading, setConfirmLoading] = useState(false)

    useEffect(() => {
        setSecretFormData(secret)
    }, [secret])

    const edit = secret ? true : false

    const secretSchema = {
        type: "object",
        $schema: "http://json-schema.org/draft-07/schema#",
        properties: {
            key: {
                type: "string",
                description: "The key of the secret.",
                pattern: "^[A-Z_]+$",
            },
            value: {
                type: "string",
                description: "The value of the secret.",
            },
        },
        required: ["key", "value"],
    }

    const title = edit ? "Edit Secret" : "Create Secret"

    const handleOk = () => {
        setConfirmLoading(true)

        const secretFormValid = secretFormRef.current.validateForm()

        if (secretFormValid) {
            createSecret({
                variables: {
                    key: secretFormData.key,
                    value: secretFormData.value,
                },
            })
                .catch((error) => {
                    message.error(error.message)
                    console.log(error)
                })
                .then(() => {
                    const successMessage = edit ? "edited" : "created"
                    message.success(`Secret ${successMessage} successfully.`)
                    onClose()
                    resetForm()
                })
        }

        setConfirmLoading(false)
    }

    function inputErrors(errors) {
        return errors.map((error) => {
            if (error.name === "pattern") {
                error.message = "only uppercase letters and underscores are allowed"
            }
            return error
        })
    }

    const handleInputChange = (e) => {
        const { formData } = e
        if (formData.key) {
            formData.key = formData.key.toUpperCase()
        }
        setSecretFormData(formData)
    }
    const resetForm = () => {
        setSecretFormData({})
    }
    const handleCancel = () => {
        onClose()
        resetForm()
    }

    return (
        <Modal title={title} open={open} confirmLoading={confirmLoading} okText="Submit" onOk={handleOk} onCancel={handleCancel} width={800}>
            <Row style={{ width: "100%", marginBottom: "20px" }}>
                <XForm
                    ref={secretFormRef}
                    style={{ marginBottom: "20px" }}
                    schema={secretSchema}
                    hideButton
                    liveValidate={true}
                    formData={secretFormData}
                    onChange={(e) => {
                        handleInputChange(e)
                        setSecretFormData(e.formData)
                    }}
                    transformErrors={inputErrors}
                ></XForm>
            </Row>
        </Modal>
    )
}
