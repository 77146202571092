import React, { useState } from "react"
import { Select, Tag } from "antd"

import { theme } from "../../../../style/antd/theme"
import AssemblyFieldLayout from "./AssemblyFieldLayout"

const { Option } = Select

export const AssemblySelectField = (props) => {
    const [value, setValue] = useState(props.formData)

    const placeholder = props.schema?.placeholder
    const value_key = props.schema?.value_key || "const"
    const label_key = props.schema?.label_key || "title"
    const options = props?.schema?.oneOf || props.schema?.enum

    const renderOptions = () => {
        if (Array.isArray(options) && options.length >= 1) {
            return options.map((item, index) => (
                <Option key={item[value_key]} value={item[value_key]}>
                    {item[label_key]}
                </Option>
            ))
        } else {
            console.log("No Options found for Select.")
            return []
        }
    }

    const handleChange = (value) => {
        console.log(value)
        setValue(value)
        if (props.onChange) props.onChange(value)
    }

    function tagRender(tagprops) {
        const { label, closable, onClose } = tagprops
        const onPreventMouseDown = (event) => {
            event.preventDefault()
            event.stopPropagation()
        }
        return (
            <Tag color={props.schema?.tag_color || theme["primary-color-lavender"]} onMouseDown={onPreventMouseDown} closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
                {label}
            </Tag>
        )
    }

    return (
        <AssemblyFieldLayout {...props}>
            <Select allowClear placeholder={placeholder} style={{ width: "100%" }} value={value} onChange={handleChange} tagRender={tagRender}>
                {renderOptions()}
            </Select>
        </AssemblyFieldLayout>
    )
}

export default AssemblySelectField
