import { useQuery, gql } from "@apollo/client"
import { useEffect, useState } from "react"
import { message } from "antd"

const GET_SCHEDULES_FOR_CHAIN = gql`
    query GetSchedulesForInputs {
        flow_schedule {
            name
            id
            is_aggregating
            flow {
                flow_settings
                aggregation_flow_settings
            }
        }
    }
`

export function getInputOptions(schedules) {
    let inputOptions = []

    if (Array.isArray(schedules)) {
        schedules.forEach((schedule) => {
            let flowSettings = schedule?.flow?.flow_settings
            let aggregationFlowSettings = schedule?.flow?.aggregation_flow_settings
            let isAggregating = schedule?.is_aggregating

            if (isAggregating && Array.isArray(aggregationFlowSettings?.outputs) && aggregationFlowSettings?.outputs.length) {
                aggregationFlowSettings?.outputs.forEach((output) => {
                    inputOptions.push({
                        title: `${schedule.name} > ${output}`,
                        const: `#ASSEMBLYINPUT::aggregation_flow::${schedule.id}::${output}`,
                    })
                })
            } else if (Array.isArray(flowSettings?.outputs) && flowSettings?.outputs.length) {
                flowSettings?.outputs.forEach((output) => {
                    inputOptions.push({
                        title: `${schedule.name} > ${output}`,
                        const: `#ASSEMBLYINPUT::flow::${schedule.id}::${output}`,
                    })
                })
            }
        })
    }

    return inputOptions
}

export function useInputOptions() {
    const { loading, error, data } = useQuery(GET_SCHEDULES_FOR_CHAIN)
    const [inputOptions, setInputOptions] = useState([])

    if (error) {
        message.error(error.message)
        console.log(error)
    }
    useEffect(() => {
        const schedules = data?.flow_schedule
        const options = getInputOptions(schedules)
        setInputOptions(options)
    }, [data])

    return { loading, error, inputOptions }
}
