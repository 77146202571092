import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { Layout, Row, Typography } from "antd"
import Icon from "@ant-design/icons"

import SideMenu from "./SideMenu"
import { ReactComponent as assemblyLogo } from "@/assets/assembly_new_min.svg"

const { Sider } = Layout
const { Text } = Typography

export const SideBar = (props) => {
    const [collapsed, setCollapsed] = useState(false)
    let navigate = useNavigate()

    return (
        <Sider collapsible collapsed={collapsed} onCollapse={setCollapsed} width={250}>
            <Row
                wrap={false}
                gutter={[12, 12]}
                align="center"
                justify={"center"}
                onClick={() => {
                    navigate("/")
                }}
                style={{
                    marginLeft: "0px",
                    padding: "5px",
                    paddingRight: "10px",
                    marginBottom: "0px",
                    marginTop: "0px",
                    height: "64px",
                    verticalAlign: "baseline",
                }}
            >
                <Icon component={assemblyLogo} style={{ fontSize: "40px" }} />

                {!collapsed && (
                    <Text
                        strong
                        style={{
                            fontFamily: "Arial",
                            fontSize: "26px",
                            lineHeight: "40px",
                            color: "white",
                            marginLeft: "14px",
                        }}
                    >
                        assembly
                    </Text>
                )}
            </Row>

            <SideMenu collapsed={collapsed}></SideMenu>
        </Sider>
    )
}
export default SideBar
