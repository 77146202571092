import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { Typography, Space, Card, message, Row } from "antd"
import { PlusOutlined, MinusSquareFilled, RightSquareOutlined, DeleteOutlined, ReloadOutlined } from "@ant-design/icons"
import { gql, useMutation } from "@apollo/client"
import { DateTime } from "luxon"

import { theme } from "../../../style/antd/theme"

import { RunStateTag } from "../../common/StateTags"
import HashPattern from "../../common/HashPattern"
import XTable from "../../common/XTable"
import XButton from "../../common/XButton"
import ChildRunTable from "./ChildRunTable"

const { Title, Text } = Typography

const DELETE_FLOW_RUN = gql`
    mutation MyMutation($flowRunId: uuid = "") {
        delete_flow_run_by_pk(id: $flowRunId) {
            id
        }
    }
`
const RE_RUN_FLOW = gql`
    mutation ReRunFlowMutation($object: flow_run_insert_input = {}) {
        insert_flow_run_one(object: $object) {
            id
        }
    }
`

export const PanelRuns = (props) => {
    const { title, flowRuns, loading, style } = props
    const navigate = useNavigate()
    const [deleteFlowRun] = useMutation(DELETE_FLOW_RUN)
    const [reRunFlow] = useMutation(RE_RUN_FLOW)
    const [expandedFlows, setExpandedFlows] = useState([])

    const columns = [
        {
            title: "ID",
            dataIndex: ["id"],
            key: "id",
            width: "40px",
            render: (text, record, index) => {
                return (
                    <Space>
                        <HashPattern tooltipConfig={{ title: record.id, width: "350px" }} text={record.id} copy={record.id}></HashPattern>
                    </Space>
                )
            },
        },
        {
            title: "Flow",
            dataIndex: ["flow", "name"],
            key: "flow",
            sort: true,
            width: "200px",
            // search: true,
            filter: true,
            render: (text, record, index) => {
                return (
                    <Space>
                        <Text style={{ fontSize: "11px" }}>{text}</Text>
                        {/* <HashPattern text={text} /> */}
                    </Space>
                )
            },
        },
        {
            title: "Schedule",
            dataIndex: ["flow_schedule", "name"],
            key: "flow_schedule",
            sort: true,
            filter: true,
            width: "200px",
            // search: true,
            render: (text, record, index) => {
                if (!text) {
                    return (
                        <Space>
                            <Text style={{ fontSize: "11px" }}>{"No Schedule"}</Text>
                        </Space>
                    )
                } else {
                    return (
                        <Space>
                            {/*<HashPattern text={text} />*/}
                            <Text style={{ fontSize: "11px" }}>{text}</Text>
                        </Space>
                    )
                }
            },
        },
        {
            title: "Times",
            dataIndex: "schedule_time",
            key: "schedule_time",
            sort: true,
            width: "154px",
            render: (text, record, index) => {
                let scheduleTime = record?.schedule_time
                let startTime = record?.start_time
                let endTime = record?.end_time

                return (
                    <div>
                        <Row justify="space-between">
                            <Text style={{ fontSize: "11px" }}>Scheduled:</Text>
                            <Text style={{ fontSize: "11px" }}>{scheduleTime ? DateTime.fromISO(scheduleTime).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS) : "-"}</Text>
                        </Row>
                        <Row justify="space-between">
                            <Text style={{ fontSize: "11px" }}>Started:</Text>
                            <Text style={{ fontSize: "11px" }}>{startTime ? DateTime.fromISO(startTime).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS) : "-"}</Text>
                        </Row>
                        <Row justify="space-between">
                            <Text style={{ fontSize: "11px" }}>Finished:</Text>
                            <Text style={{ fontSize: "11px" }}>{endTime ? DateTime.fromISO(endTime).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS) : "-"}</Text>
                        </Row>
                    </div>
                )
            },
        },
        {
            title: "State",
            dataIndex: "state",
            key: "state",
            sort: true,
            filter: true,
            search: true,
            width: "120px",
            render: (text, record, index) => {
                const title = record?.state_details?.message
                return <RunStateTag state={text} tooltipConfig={{ title: title }} />
            },
        },
        {
            title: "Actions",
            key: "action",
            sorter: true,
            width: "125px",
            render: (text, record, index) => (
                <Space size="small">
                    <XButton
                        tooltipConfig={{ title: "Flow Run Details" }}
                        onClick={() => {
                            navigate(`/runs/${record.id}`)
                        }}
                        type="primary"
                        icon={<RightSquareOutlined />}
                    ></XButton>
                    <XButton
                        tooltipConfig={{ title: "Delete FlowRun" }}
                        popConfirmConfig={{
                            title: `Are you sure you want to delete this FlowRun?`,
                            onConfirm: () => {
                                handleDeleteRun(record.id)
                            },
                        }}
                        type="primary"
                        icon={<DeleteOutlined />}
                    ></XButton>
                    {!props?.hideReRunBtn && (
                        <XButton
                            tooltipConfig={{ title: "Re-Run Flow" }}
                            onClick={() => {
                                handleReRun(record)
                            }}
                            type="primary"
                            icon={<ReloadOutlined />}
                        ></XButton>
                    )}
                </Space>
            ),
        },
    ]

    const handleReRun = (record) => {
        reRunFlow({
            variables: {
                object: {
                    schedule_time: DateTime.utc(),
                    state: "User Rerun",
                    state_details: { message: "Manual user re run." },
                    parameters: record.parameters,
                    prior_run_id: record.id,
                    flow_id: record.flow_id,
                    flow_schedule_id: record.flow_schedule_id,
                    parent_run_id: record.parent_run_id,
                    is_aggregation_run: record.is_aggregation_run,
                },
            },
        })
            .then(() => {
                message.success("Successful Rerun.")
            })
            .catch((error) => {
                console.log("Error occurred during reRunFlow:", error)
                message.error(error.message)
            })
    }

    const handleDeleteRun = (flowRunId) => {
        deleteFlowRun({ variables: { flowRunId: flowRunId } })
            .then(() => {
                message.success("Flow run deleted successfully.")
            })
            .catch((error) => {
                console.error(`Error deleting flow run: ${error.message}`)
                message.error(error.message)
            })
    }

    return (
        <Card style={style}>
            <Title level={5} style={{ marginBottom: "18px" }}>
                {title}
            </Title>
            <XTable
                columns={columns}
                dataSource={flowRuns}
                rowKey="id"
                size="small"
                loading={loading}
                rowClassName={(record, index) => {
                    if (expandedFlows.includes(record.id)) return "expandedTableRow"
                    return null
                }}
                pagination={{
                    hideOnSinglePage: true,
                }}
                expandable={{
                    rowExpandable: (record) => record.is_aggregation_run === true,
                    onExpandedRowsChange: (expandedRows) => {
                        setExpandedFlows(expandedRows)
                    },
                    expandedRowRender: (record, index, indent, expanded) => <ChildRunTable style={{ marginLeft: "32px" }} flowRun={record}></ChildRunTable>,
                    expandIcon: ({ expanded, onExpand, record }) => {
                        if (!record.is_aggregation_run) return <div></div>
                        if (expanded) return <MinusSquareFilled onClick={(e) => onExpand(record, e)} style={{ color: theme["primary-color-lavender"] }} />
                        return <PlusOutlined onClick={(e) => onExpand(record, e)} />
                    },
                }}
            ></XTable>
        </Card>
    )
}

export default PanelRuns
