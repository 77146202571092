import React, { useState } from "react"
import { message, Row, Col, Typography } from "antd"
import { useMutation, gql } from "@apollo/client"
import { DateTime } from "luxon"

import ModalEditSchedule from "./ModalScheduleEdit"
import XNode from "../XNode"
import { theme } from "../../../../../style/antd/theme"
import GitLink from "../../../XGitLink"
import { RocketOutlined, LoadingOutlined } from "@ant-design/icons"
import XButton from "../../../XButton"

import nodeSizes from "../nodeSizes"

const { Text } = Typography
const MANUAL_TRIGGER_RUN = gql`
    mutation CreateTriggerEvent($trigger_event: trigger_event_insert_input!) {
        insert_trigger_event_one(object: $trigger_event) {
            id
        }
    }
`

const TriggerButton = (props) => {
    const [loading, setLoading] = useState(false)
    const [createTrigger] = useMutation(MANUAL_TRIGGER_RUN)
    const schedule = props?.schedule

    const handleManualTrigger = (e) => {
        setLoading(true)
        createTrigger({
            variables: {
                trigger_event: {
                    schedule_id: schedule.id,
                    schedule_time: DateTime.utc(),
                    type: "MANUAL_TRIGGER_EVENT",
                },
            },
        })
            .then(() => {
                message.success("Schedule triggered successfully.")
            })
            .catch((error) => {
                message.error(error.message)
            })
        setLoading(false)
        e.stopPropagation()
    }

    return (
        <XButton
            type="link"
            size="small"
            icon={loading ? <LoadingOutlined /> : <RocketOutlined />}
            popConfirmConfig={{ title: "Do you want to trigger this Schedule?", onConfirm: handleManualTrigger }}
        />
    )
}

export const ScheduleNode = (node) => {
    const schedule = node?.data?.schedule
    const flow = schedule?.flow
    const flowName = flow?.name
    const onUpdate = node?.data?.onUpdate
    const [modalOpen, setModalOpen] = useState(false)

    const maxWidth = nodeSizes.SCHEDULE.nodeWidth
    const maxHeight = nodeSizes.SCHEDULE.nodeHeight

    const effectColor = theme["primary-color-lavender"]

    return (
        <div>
            <XNode
                node={node}
                tagText={flowName}
                // headerColor={theme["primary-color-lavender-dark"]}
                subTag={<TriggerButton schedule={schedule} />}
                effectColor={effectColor}
                onClick={(event) => {
                    setModalOpen(true)
                }}
                maxHeight={maxHeight}
                maxWidth={maxWidth}
                input
                output
            >
                <Row>
                    <Col style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
                        {node?.data?.name !== flowName && (
                            <>
                                <Text style={{ color: "#bbb", fontSize: "10px", fontWeight: "bold", marginLeft: "4x", marginRight: "4px" }}>Schedule Name:</Text>
                                <br></br>
                                <Text style={{ color: "#bbb", marginLeft: "4x", marginRight: "4px" }}>{node?.data?.name}</Text>
                            </>
                        )}
                    </Col>
                </Row>

                <GitLink schedule={schedule.flow.gitlink} gitlinkRecord={schedule.flow.gitlink} fontSize={"8px"} />
            </XNode>

            {modalOpen && (
                <ModalEditSchedule
                    open={modalOpen}
                    onClose={() => {
                        setModalOpen(false)
                    }}
                    onUpdate={onUpdate}
                    scheduleId={schedule.id}
                ></ModalEditSchedule>
            )}
        </div>
    )
}

export default ScheduleNode
