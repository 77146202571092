import { Button, Input, Row } from "antd"
import React, { useEffect } from "react"

// FilterDropdownProps
// https://github.com/ant-design/ant-design/blob/ecc54dda839619e921c0ace530408871f0281c2a/components/table/interface.tsx#L79

export const SearchFilter = (props) => {
    const { setSelectedKeys, selectedKeys, confirm, clearFilters, visible, close } = props
    const inputRef = React.useRef(null)

    useEffect(() => {
        if (visible) {
            setTimeout(() => {
                inputRef.current.focus({ cursor: "end" })
            }, 10)
        }
    }, [visible])

    const showReset = selectedKeys[0] !== undefined

    return (
        <div style={{ padding: 8 }}>
            <Input
                ref={inputRef}
                placeholder={"Search"}
                value={selectedKeys[0]}
                onChange={(e) => {
                    setSelectedKeys(e.target.value ? [e.target.value] : [])
                    confirm({ closeDropdown: false })
                }}
                onPressEnter={() => confirm()}
                style={{ display: "block" }}
            />

            {showReset && (
                <Row justify="end" style={{ marginTop: "8px" }}>
                    <Button
                        onClick={() => {
                            clearFilters()
                            confirm()
                            close()
                        }}
                        type="primary"
                        size="small"
                        shape="round"
                        style={{ width: 70 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close()
                        }}
                    >
                        Close
                    </Button>
                </Row>
            )}
        </div>
    )
}

export default SearchFilter
