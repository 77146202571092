import React from "react"

import { Avatar, Dropdown, Row, Col } from "antd"
import { UserOutlined } from "@ant-design/icons"

import { useMsal } from "@azure/msal-react"
// import { Client } from "@microsoft/microsoft-graph-client"

const ProfilePicture = () => {
    const imageUrl = localStorage.getItem("profileImage")

    if (imageUrl) return <Avatar size={38} style={{ margin: "5px" }} src={imageUrl}></Avatar>
    return <Avatar size={38} style={{ margin: "5px" }} icon={<UserOutlined />}></Avatar>
}

export const UserSettings = (props) => {
    const { instance, accounts } = useMsal()

    const handleMenuClick = (e) => {
        if (e.key === "logout") instance.logout()
    }

    const items = [
        {
            label: "Logout",
            key: "logout",
        },
    ]

    const menuProps = {
        items,
        onClick: handleMenuClick,
    }

    return (
        <Dropdown menu={menuProps} placement="bottomRight">
            <Row align="middle">
                <Col align="right" flex="auto">
                    {accounts[0].name}
                </Col>
                <ProfilePicture />
            </Row>
        </Dropdown>
    )
}
