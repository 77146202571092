import React, { useState } from "react"
import { Row, Typography } from "antd"

import XNode from "../XNode"
import ModalEditCron from "./ModalEditCron"
import nodeSizes from "../nodeSizes"

const { Text } = Typography

const CronNode = (node) => {
    const cronTrigger = node?.data.trigger
    const onUpdate = node?.data?.onUpdate
    const [modalOpen, setModalOpen] = useState(false)

    const maxWidth = nodeSizes.CRON.nodeWidth
    const maxHeight = nodeSizes.CRON.nodeHeight

    const noConnection = cronTrigger?.m2m_trigger_schedules?.length === 0

    return (
        <div>
            <XNode
                node={node}
                tagText="Cron"
                onClick={() => {
                    setModalOpen(true)
                }}
                maxHeight={maxHeight}
                maxWidth={maxWidth}
                output
                warning={noConnection}
            >
                <Row justify="center" wrap={false} style={{ height: "100%", padding: "0px" }}>
                    <Text style={{ color: "white", margin: "2px" }}>{node?.data?.name}</Text>
                </Row>
            </XNode>
            {modalOpen && (
                <ModalEditCron
                    open={modalOpen}
                    onClose={() => {
                        setModalOpen(false)
                    }}
                    onUpdate={onUpdate}
                    cron={cronTrigger}
                ></ModalEditCron>
            )}
        </div>
    )
}

export default CronNode
