import { Tag, Tooltip } from "antd"
import { ClockCircleOutlined, SyncOutlined, CloseCircleOutlined, CheckCircleOutlined } from "@ant-design/icons"

// import { theme } from "../../style/antd/theme"

export const InnerRunStateTag = (props) => {
    const { state, ...tagProps } = props

    if (state === "Scheduled")
        return (
            <Tag icon={<ClockCircleOutlined />} color="default" style={{ fontSize: "10px" }} {...tagProps}>
                {state}
            </Tag>
        )
    if (state === "Running")
        return (
            <Tag icon={<SyncOutlined spin />} color="processing" style={{ fontSize: "10px" }} {...tagProps}>
                {state}
            </Tag>
        )
    if (state === "Failed")
        return (
            <Tag icon={<CloseCircleOutlined />} color="error" style={{ fontSize: "10px" }} {...tagProps}>
                {state}
            </Tag>
        )
    if (state === "Success")
        return (
            <Tag icon={<CheckCircleOutlined />} color="success" style={{ fontSize: "10px" }} {...tagProps}>
                {state}
            </Tag>
        )

    return (
        <Tag color="default" style={{ fontSize: "10px" }} {...tagProps}>
            {state}
        </Tag>
    )
}

export const RunStateTag = (props) => {
    const { tooltipConfig, ...stateProps } = props

    if (!tooltipConfig) return <InnerRunStateTag {...stateProps}></InnerRunStateTag>

    return (
        <Tooltip {...tooltipConfig}>
            <InnerRunStateTag {...stateProps}></InnerRunStateTag>
        </Tooltip>
    )
}
