
import { theme } from "../../../../style/antd/theme"

export const getTaskStateBgColor = (showTastState, state) => {
    // if (!showTastState) return theme["primary-color-blue"]
    if (!showTastState) return "#ddd"
    if (!state) return "#d8ced7"

    if (state === "Success") return "#5cb85c"
    if (state === "Failed") return "#f5222d"
    if (state === "Running") return "#88aaff"
    if (state === "Initialized") return "#c0c0c0"
    return theme["warning"]
}