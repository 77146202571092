import { useCallback, useState, useEffect } from "react"

import { ApolloClient, InMemoryCache, split, HttpLink, from } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { WebSocketLink } from "@apollo/client/link/ws"
import { onError } from "@apollo/client/link/error"
import { getMainDefinition } from "@apollo/client/utilities"

const apiHost = window?._env_?.REACT_APP_API_HOST

// function to dynamically generate a new client when a new token is available
export const createNewClient = (token) => {
    if (!token || token === "") return null

    const authLink = setContext((_, { headers }) => {
        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : "",
            },
        }
    })

    const httpLink = new HttpLink({
        uri: `https://${apiHost}`,
    })

    const wsLink = new WebSocketLink({
        uri: `wss://${apiHost}`,
        options: {
            lazy: true,
            reconnect: true,
            connectionParams: {
                headers: {
                    Authorization: token ? `Bearer ${token}` : "",
                },
            },
        },
    })

    const splitLink = split(
        ({ query }) => {
            const definition = getMainDefinition(query)
            return definition.kind === "OperationDefinition" && definition.operation === "subscription"
        },
        wsLink,
        authLink.concat(httpLink)
    )

    const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) graphQLErrors.forEach(({ message, locations, path }) => console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`))
        if (networkError) console.log(`[Network error]: ${networkError}`)
    })

    const apolloClient = new ApolloClient({
        link: from([errorLink, splitLink]),
        cache: new InMemoryCache(),
        resolvers: {},
    })
    console.log(apolloClient)
    return apolloClient
}

export const useApolloClient = (initialToken) => {
    const [token, setToken] = useState(initialToken);
    const [client, setClient] = useState(createNewClient(initialToken));

    useEffect(() => {
        setClient(createNewClient(token));
    }, [token]);

    const updateToken = useCallback((newToken) => {
        setToken(newToken);
    }, []);

    return [client, updateToken];
}
