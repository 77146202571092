import React from "react"
import { Typography, Space, message } from "antd"
import { gql, useQuery } from "@apollo/client"
import { DateTime } from "luxon"
import { RunStateTag } from "../../common/StateTags"
import HashPattern from "../../common/HashPattern"
import XTable from "../../common/XTable"
import ButtonDeleteFlowRun from "../../models/flow_runs/ButtonDeleteFlowRun"
import ButtonFlowRunDetails from "../../models/flow_runs/ButtonFlowRunDetails"

const { Text } = Typography

const GET_CHILD_RUNS = gql`
    query GetChildRuns($flowRunId: uuid!) {
        flow_run(where: { parent_run_id: { _eq: $flowRunId } }) {
            id
            schedule_time
            start_time
            end_time
            parameters
            prior_run_id
            state
            state_details
            updated_at
            created_at
            parent_run_id
            flow_schedule_id
            flow_schedule {
                name
                id
            }
            flow_id
            flow {
                id
                name
            }
            is_aggregation_run
            is_child_run
        }
    }
`

export const ChildRunTable = ({ flowRun }) => {
    const { loading, error, data } = useQuery(GET_CHILD_RUNS, { variables: { flowRunId: flowRun.id } })

    if (error) {
        message.error(error.message)
        console.log(error.message)
    }

    const flowRuns = data?.flow_run || []

    const columns = [
        {
            title: "ID",
            dataIndex: ["id"],
            key: "id",
            width: "40px",
            render: (text, record, index) => {
                return (
                    <Space>
                        <HashPattern tooltipConfig={{ title: record.id }} text={record.id} copy={record.id} size={12}></HashPattern>
                    </Space>
                )
            },
        },
        {
            title: "Scheduled",
            dataIndex: "schedule_time",
            key: "schedule_time",
            sort: true,
            filter: true,
            search: true,
            width: "140px",
            render: (text, record, index) => {
                return <Text style={{ fontSize: "11px" }}>{text ? DateTime.fromISO(text).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS) : ""}</Text>
            },
        },
        {
            title: "Started",
            dataIndex: "start_time",
            key: "start_time",
            sort: true,
            filter: true,
            search: true,
            width: "140px",
            render: (text, record, index) => {
                return <Text style={{ fontSize: "11px" }}>{text ? DateTime.fromISO(text).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS) : ""}</Text>
            },
        },
        {
            title: "Finished",
            dataIndex: "end_time",
            key: "end_time",
            sort: true,
            filter: true,
            search: true,
            width: "140px",
            render: (text, record, index) => {
                return <Text style={{ fontSize: "11px" }}>{text ? DateTime.fromISO(text).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS) : ""}</Text>
            },
        },
        {
            title: "State",
            dataIndex: "state",
            key: "state",
            sort: true,
            filter: true,
            search: true,
            width: "120px",
            render: (text, record, index) => {
                return <RunStateTag state={text} />
            },
        },
        {
            title: "Actions",
            key: "action",
            sorter: true,
            width: "125px",
            render: (text, record, index) => (
                <Space size="small">
                    <ButtonFlowRunDetails id={record.id} />
                    <ButtonDeleteFlowRun id={record.id} />
                </Space>
            ),
        },
    ]

    return (
        <XTable
            columns={columns}
            dataSource={flowRuns}
            rowKey="id"
            size="small"
            loading={loading}
            pagination={{
                hideOnSinglePage: true,
            }}
        ></XTable>
    )
}

export default ChildRunTable
