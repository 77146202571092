import React, { useState } from "react"

import { DownOutlined, ClockCircleOutlined } from "@ant-design/icons"
import { Button, Dropdown, Space, theme, Select, Spin, message } from "antd"
import { useQuery, gql } from "@apollo/client"
import XButton from "../XButton"
import ModalScheduleFlow from "../Modals/ModalScheduleFlow"

const { useToken } = theme

const GET_FLOWS = gql`
    query GetFlows {
        flow {
            id
            name
            tags
            project {
                name
                id
            }
        }
    }
`

const SearchAddFlowScheduleButton = ({ onChange, ...btnProps }) => {
    const { token } = useToken()
    const { loading, error, data } = useQuery(GET_FLOWS)
    const [selectedFlow, setSelectedFlow] = useState(null)
    const [open, setOpen] = useState(false)

    if (error) {
        console.log(error)
        message.error(error.message)
    }

    const dropdownContentStyle = {
        backgroundColor: token.colorBgElevated,
        borderRadius: token.borderRadiusLG,
        boxShadow: token.boxShadowSecondary,
        padding: "10px",
    }
    let flowOptions = []
    if (Array.isArray(data?.flow))
        flowOptions = data.flow.map((flow) => ({
            value: flow.id,
            label: flow.name,
            flow: flow,
        }))

    return (
        <Dropdown
            trigger={["click"]}
            destroyPopupOnHide={false}
            open={open}
            onOpenChange={(visible) => {
                if (!visible) setSelectedFlow(null)
                setOpen(false)
            }}
            dropdownRender={() => {
                if (loading)
                    return (
                        <div style={dropdownContentStyle}>
                            <Spin></Spin>
                        </div>
                    )
                return (
                    <div style={dropdownContentStyle}>
                        <Select
                            autoFocus={true}
                            showSearch
                            style={{ width: 300 }}
                            placeholder="Search Flow"
                            filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                            filterSort={(optionA, optionB) => (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())}
                            options={flowOptions}
                            onChange={(value, { flow }) => {
                                setSelectedFlow(flow)
                            }}
                        />
                        <XButton
                            tooltipConfig={{ title: "Add Flow" }}
                            modalConfig={{
                                Modal: ModalScheduleFlow,
                                props: {
                                    flowId: selectedFlow?.id,
                                    onClose: () => {
                                        onChange()
                                    },
                                },
                            }}
                            type="primary"
                            icon={<ClockCircleOutlined />}
                            onClick={() => {
                                setOpen(false)
                            }}
                        ></XButton>
                    </div>
                )
            }}
        >
            <Button type="primary" shape="round" {...btnProps} onClick={() => setOpen(true)}>
                <Space>
                    Add Flow
                    <DownOutlined />
                </Space>
            </Button>
        </Dropdown>
    )
}

export default SearchAddFlowScheduleButton
