import AssemblyCronField from "./FormComponents/AssemblyCronField"
import AssemblyJsonEditField from "./FormComponents/AssemblyJsonEditField"
import AssemblyObjectField from "./FormComponents/AssemblyObjectField"
import AssemblyField from "./FormComponents/AssemblyField"
import AssemblyArrayField from "./FormComponents/AssemblyArrayField"
import AssemblyRelativeDateTimeField from "./FormComponents/AssemblyRelativeDateTimeField"
import AssemblyEmptyFieldTemplate from "./FormComponents/AssemblyEmptyFieldTemplate"
import AssemblyNumberField from "./FormComponents/AssemblyNumberField"
import AssemblyMultiSelectField from "./FormComponents/AssemblyMultiSelectField"
import AssemblyBooleanField from "./FormComponents/AssemblyBooleanField"
import AssemblySelectField from "./FormComponents/AssemblySelectField"
import AssemblyInputField from "./FormComponents/AssemblyInputField"
import AssemblyEmptyObjectFieldTemplate from "./FormComponents/AssemblyEmptyObjectFieldTemplate"

function schemaBuilder(schema, readOnly, level = 0, otherSchemaProps = null) {
    const uiSchema = {}

    if (schema.hasOwnProperty("ui:order")) uiSchema["ui:order"] = schema["ui:order"]

    if (schema.type === "object") {
        if (Array.isArray(schema?.oneOf)) {
            uiSchema["ui:FieldTemplate"] = AssemblySelectField
        } else if (schema.properties) {
            Object.entries(schema.properties).forEach(([key, value]) => {
                if (level > 0) {
                    // Array and object field templates are always rendered inside of the FieldTemplate.
                    //To fully customize an object field template, you may need to specify both ui:FieldTemplate and ui:ObjectFieldTemplate
                    uiSchema["ui:FieldTemplate"] = AssemblyEmptyObjectFieldTemplate
                    uiSchema["ui:ObjectFieldTemplate"] = AssemblyObjectField
                }
                uiSchema[key] = schemaBuilder(value, readOnly, level + 1)
            })
        } else {
            uiSchema["ui:FieldTemplate"] = AssemblyJsonEditField
        }
    } else if (schema.type === "array") {
        if (Array.isArray(schema?.anyOf)) {
            uiSchema["ui:FieldTemplate"] = AssemblyMultiSelectField
        } else if (schema.items) {
            // Array and object field templates are always rendered inside of the FieldTemplate.
            //To fully customize an object field template, you may need to specify both ui:FieldTemplate and ui:ObjectFieldTemplate
            uiSchema["ui:FieldTemplate"] = AssemblyEmptyFieldTemplate
            uiSchema["ui:ArrayFieldTemplate"] = AssemblyArrayField
            uiSchema["items"] = {}
            uiSchema["items"] = schemaBuilder(schema.items, readOnly, level + 1, { isArrayItem: true })
        }
    } else if (schema.type === "string" && Array.isArray(schema?.oneOf)) {
        uiSchema["ui:FieldTemplate"] = AssemblySelectField
    } else if (schema.type === "string" && schema.subtype === "cron") {
        uiSchema["ui:FieldTemplate"] = AssemblyCronField
    } else if (schema.type === "number") {
        uiSchema["ui:FieldTemplate"] = AssemblyNumberField
    } else if (schema.type === "boolean") {
        uiSchema["ui:FieldTemplate"] = AssemblyBooleanField
    }

    // else if (schema.type === 'string' && schema?.subtype === 'timezone') {
    //     // uiSchema["ui:FieldTemplate"] = AssemblyTimezoneEditField
    // }
    else if (
        schema.anyOf &&
        Array.isArray(schema.anyOf) &&
        schema.anyOf.every((option) => {
            return option.type === "string" && ["Date", "Datetime", "Relative Date", "Relative Datetime"].includes(option.title)
        })
    ) {
        uiSchema["ui:FieldTemplate"] = AssemblyRelativeDateTimeField
    } else if ((schema.type === "number", schema.subtype === "input")) {
        uiSchema["ui:FieldTemplate"] = AssemblyInputField
    } else uiSchema["ui:FieldTemplate"] = AssemblyField

    uiSchema["ui:otherProps"] = { ...otherSchemaProps, level: level }
    return uiSchema
}

export default schemaBuilder
