import React from "react"
import { Row, Typography } from "antd"

import XNode from "../XNode"
import { getTaskStateBgColor } from "../nodeUtils"
import BlobDownloadButton from "../../../FunctionButtons/BlobDownloadButton"

const { Text } = Typography

const TaskNode = (node) => {
    const showTaskState = node?.data?.showTaskState
    const taskRun = node?.data?.taskRun

    const effectColor = getTaskStateBgColor(showTaskState, taskRun?.state)

    const cloudResult = node?.data?.taskRun?.state_details?.context?.assembly_cloud_result
    const cloudOutput = node?.data?.taskRun?.state_details?.context?.task_output

    return (
        <XNode
            node={node}
            tagText="Task"
            tagColor={effectColor}
            effectColor={effectColor}
            subTag={<Text style={{ color: effectColor, fontSize: "6px", marginLeft: "2px", marginRight: "2px" }}>{taskRun?.state}</Text>}
        >
            <Row justify="center" align="middle" wrap={false} style={{ height: "100%", padding: "0px" }}>
                <Text style={{ color: "white", margin: "2px" }}>{node?.data?.name}</Text>
                {cloudResult && <BlobDownloadButton blobLocation={cloudResult} size="small" type="secondary" style={{ margin: "3px", marginLeft: "6px" }} />}
                {cloudOutput && <BlobDownloadButton blobLocation={cloudOutput} size="small" type="secondary" style={{ margin: "3px", marginLeft: "6px" }} />}
            </Row>
        </XNode>
    )
}

export default TaskNode
