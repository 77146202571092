import React from "react"

import { DownOutlined, ApiOutlined, ClockCircleOutlined } from "@ant-design/icons"
import { Button, Dropdown, message, Space } from "antd"
import { useMutation, gql } from "@apollo/client"

const ADD_TRIGGER = gql`
    mutation MyMutation($trigger: trigger_insert_input!) {
        insert_trigger_one(object: $trigger) {
            id
        }
    }
`

const characters = "abcdefghijklmnopqrstuvwxyz0123456789"

function generateString(length) {
    let result = " "
    const charactersLength = characters.length
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }

    return result
}

const AddTriggerButton = ({ onChange, ...btnProps }) => {
    const [addTrigger] = useMutation(ADD_TRIGGER)

    const items = [
        {
            label: "CRON",
            key: "CRON",
            icon: <ClockCircleOutlined />,
        },
        {
            label: "API",
            key: "API",
            icon: <ApiOutlined />,
        },
    ]

    const menuProps = {
        items,
        onClick: (e) => {
            const { key } = e
            let trigger = null

            if (key === "CRON") {
                trigger = {
                    type: "CRON",
                    expression: "* * * 9 0",
                }
            } else if (key === "API") {
                trigger = {
                    type: "API",
                    api: generateString(6),
                }
            }

            if (trigger) {
                addTrigger({ variables: { trigger } })
                    .then((response) => {
                        if (onChange) onChange()
                        message.success("Added API Trigger")
                    })
                    .catch((error) => {
                        message.error(error.message)
                        console.log(error)
                    })
                    .finally(() => {})
            }
        },
    }

    return (
        <Dropdown menu={menuProps}>
            <Button {...btnProps}>
                <Space>
                    Add Trigger
                    <DownOutlined />
                </Space>
            </Button>
        </Dropdown>
    )
}

export default AddTriggerButton
