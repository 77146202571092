const clientId = window?._env_?.REACT_APP_CLIENT_ID
const authority = window?._env_?.REACT_APP_API_AUTHORITY

export const msalConfig = {
    auth: {
        clientId: clientId,
        authority: authority,
        redirectUri: "/",
        postLogoutredirectUri: "/",
        navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateinCookie: false,
    },
}

export const loginRequest = {
    scopes: ["user.read"],
}
