import React, { useState } from "react"
import PropTypes from "prop-types"
import { theme } from "../../../style/antd/theme"
import { Typography, Space } from "antd"
import { WarningTwoTone } from "@ant-design/icons"

const { Text } = Typography
const GitLabIcon = (props) => {
    const [isHovering, setIsHovering] = useState(false)

    const handleMouseOver = () => {
        setIsHovering(true)
    }

    const handleMouseLeave = () => {
        setIsHovering(false)
    }

    const iconStyle = {
        fontSize: "18px",
        transition: "all 0.2s",
        color: isHovering ? "red" : "none",
        transform: isHovering ? "scale(1.3)" : "none",
        cursor: isHovering ? "pointer" : "default",
    }

    return (
        <svg viewBox="0 0 24 24" style={iconStyle} onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave} {...props}>
            <g data-name="logo art">
                <path fill="#040fff" d="m12 21.42 3.684-11.333H8.32L12 21.421Z"></path>
                <path fill="#EA90F8" d="m3.16 10.087-1.123 3.444a.763.763 0 0 0 .277.852l9.685 7.038-8.84-11.334Z"></path>
                <path fill="#040fff" d="M3.16 10.087h5.16L6.1 3.262a.383.383 0 0 0-.728 0L3.16 10.087Z"></path>
                <path fill="#EA90F8" d="m20.845 10.087 1.118 3.444a.763.763 0 0 1-.276.852l-9.688 7.038 8.846-11.334Z"></path>
                <path fill="#040fff" d="M20.845 10.087h-5.161L17.9 3.262a.383.383 0 0 1 .727 0l2.217 6.825Z"></path>
                <path fill="#7e288c" d="m11.999 21.421 3.685-11.334h5.161l-8.846 11.334z"></path>
                <path fill="#7e288c" d="m11.999 21.421-8.84-11.334H8.32l3.679 11.334z"></path>
            </g>
        </svg>
    )
}

GitLabIcon.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    className: PropTypes.string,
}

GitLabIcon.defaultProps = {
    width: "1em",
    height: "1em",
    className: "",
}

const GitLinkWarning = (props) => {
    const { fontSize } = props
    const warningColor = theme["warning"]

    const warningStyle = {
        fontSize: fontSize || "12px",
        color: warningColor,
        paddingLeft: "5px",
    }

    return (
        <>
            <WarningTwoTone twoToneColor={warningColor} style={{ ...warningStyle }} />
            <Text type="secondary" style={warningStyle}>
                Flow not found
            </Text>
        </>
    )
}

export default function GitLink(props) {
    const { gitlinkRecord, fontSize, schedule } = props

    const linkToFile = (gitlinkRecord) => {
        window.open(gitlinkRecord)
    }
    return !schedule ? <Space>{gitlinkRecord ? <GitLabIcon onClick={() => linkToFile(gitlinkRecord)} /> : <GitLinkWarning fontSize={fontSize} />}</Space> : null
}
