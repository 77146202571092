import React from "react"
import { Card, Spin } from "antd"
import { Scatter } from "@ant-design/plots"

const ScheduleDuration = ({ scheduleDurationData, loading }) => {
    const scheduleDurationConfig = {
        animate: false,
        data: scheduleDurationData,
        xField: "scheduleTime",
        yField: "duration",
        style: {
            height: "300px",
        },
        size: 8,
        shape: "circle",
        yAxis: {
            min: 0,
            label: {
                formatter: (v) => `${(v / 1000).toFixed(0)} s`,
            },
        },
        xAxis: {
            label: {
                formatter: (v) =>
                    new Date(v).toLocaleTimeString([], {
                        hour: "numeric",
                        minute: "numeric",
                    }),
            },
        },
        slider: {
            start: 0.0,
            end: 1.0,
        },
        color: "#51CFCB",
    }

    return (
        <Card title="Scheduling Time (24h):" size="small">
            {loading && <Spin style={{ borderRadius: "10px" }} tip="Loading..." size="large" />}
            {!loading && <Scatter {...scheduleDurationConfig} />}
        </Card>
    )
}

export default ScheduleDuration
