import React from "react"
import { Col, Row, Tooltip, Typography } from "antd"
import { InfoCircleOutlined } from "@ant-design/icons"

import { theme } from "../../../../style/antd/theme.js"

const { Text } = Typography

export const AssemblyFieldLayout = (props) => {
    const description = props.schema?.description

    const infoTooltip = (
        <React.Fragment>
            {props.uiSchema["ui:description"] || description ? (
                <Tooltip color={theme["primary-color"]} overlayClassName="" placement="topLeft" title={props.uiSchema["ui:description"] || props.schema.description} trigger="hover">
                    <InfoCircleOutlined style={{ paddingRight: "5px", color: theme["primary-color-lavender"] }} />
                </Tooltip>
            ) : (
                <InfoCircleOutlined style={{ paddingRight: "5px", color: theme["primary-color-lavender-soft"] }} />
            )}
        </React.Fragment>
    )

    let label = props.schema?.label || props.label || props.title
    if (props.uiSchema?.["ui:otherProps"]?.isArrayItem) label = null

    const title = (
        <React.Fragment>
            {label && (
                <Text strong style={{ textTransform: "capitalize", fontSize: "12px" }}>
                    {label}:
                </Text>
            )}
        </React.Fragment>
    )

    const level = props.uiSchema["ui:otherProps"].level

    return (
        <Row wrap={false} style={{ paddingTop: "4px", paddingBottom: "4px", overflow: level === 2 ? "auto" : null, flex: "1 0 auto", flexShrink: 0 }}>
            {label && (
                <Col flex={"20px"} style={{ textAlign: "center" }}>
                    {infoTooltip}
                </Col>
            )}

            {label && (
                <Col flex={"140px"} align={"left"}>
                    {title}
                    <Text strong style={{ color: theme["primary-color-lavender-dark"] }}>
                        {props.required ? " *" : ""}
                    </Text>
                </Col>
            )}

            <Col flex="auto" style={{ minWidth: "280px", flexShrink: level < 2 ? 1 : 0 }}>
                {props.children}
                <Text style={{ color: "red" }}>{props.errors}</Text>
            </Col>
        </Row>
    )
}

export default AssemblyFieldLayout
