import React from "react"
import { useNavigate } from "react-router-dom"
import { RightSquareOutlined } from "@ant-design/icons"

import XButton from "../../common/XButton"

export const ButtonFlowRunDetails = (props) => {
    const navigate = useNavigate()
    const id = props.id

    return (
        <XButton
            tooltipConfig={{ title: "Flow Run Details" }}
            onClick={() => {
                navigate(`/runs/${id}`)
            }}
            type="primary"
            icon={<RightSquareOutlined />}
        ></XButton>
    )
}

export default ButtonFlowRunDetails
