import React, { useState } from "react"
import { Row, Typography, Space, message, Tag } from "antd"
import { PlusOutlined, MinusSquareFilled, ClockCircleOutlined, DeleteOutlined, ReadOutlined } from "@ant-design/icons"
import { useQuery, useMutation, gql } from "@apollo/client"
import { DateTime } from "luxon"
import { useInterval } from "usehooks-ts"

import { theme } from "../../../style/antd/theme"
import { stringToHSLNoYellow } from "../../../utils/stringToColor"
import XTable from "../../common/XTable"
import FlowRunTable from "./FlowRunTable"
import AssemblyFlow from "../../common/XFlow/AssemblyFlow"
import XButton from "../../common/XButton"
import HashPattern from "../../common/HashPattern"
import ModalScheduleFlow from "../../common/Modals/ModalScheduleFlow"
import GitLink from "../../common/XGitLink"

const { Title, Text } = Typography

const GET_FLOWS = gql`
    query GetFlow {
        flow(order_by: { project_id: asc, name: asc }) {
            id
            name
            description
            tags
            gitlink
            flow_settings
            aggregation_flow_settings
            created_at
            updated_at
            project_id
            project {
                id
                name
                library
            }
        }
    }
`

const DELETE_FLOW = gql`
    mutation DeleteFlow($flowId: uuid!) {
        delete_flow_by_pk(id: $flowId) {
            id
        }
    }
`

export const FlowTable = (props) => {
    const { loading, error, data, refetch } = useQuery(GET_FLOWS)
    const [deleteFlow] = useMutation(DELETE_FLOW)
    const [expandedFlows, setExpandedFlows] = useState([])

    useInterval(() => {
        refetch().catch((error) => {
            message.error(error.message)
            console.log(error)
        })
    }, 60000)

    if (error) {
        message.error(error.message)
        console.log(error)
    }
    const flows = data?.flow

    const handleDeleteFlow = (flowId) => {
        deleteFlow({ variables: { flowId: flowId } })
            .then(() => {
                message.success("Flow deleted successfully.")
                refetch()
            })
            .catch((error) => {
                message.error(error.message)
                console.log(error)
            })
    }

    const columns = [
        {
            title: "Flow Name",
            dataIndex: "name",
            key: "name",
            sort: true,
            search: true,
            render: (text, record, index) => {
                return (
                    <Space>
                        <HashPattern tooltipConfig={{ title: record.id }} text={text} copy={record.id} size={24}></HashPattern>
                        <Text>{text}</Text>
                    </Space>
                )
            },
        },
        {
            title: "Project",
            dataIndex: ["project", "name"],
            key: "projectname",
            sort: true,
            filter: true,
            render: (text, record, index) => {
                return (
                    <Space>
                        <Text>{text}</Text>
                        {record?.project?.library && <ReadOutlined style={{ color: theme["primary-color-lavender"] }} />}
                    </Space>
                )
            },
        },
        {
            title: "Tags",
            dataIndex: "tags",
            key: "tags",
            sort: true,
            filter: true,
            width: "90px",
            render: (text, record, index) => {
                let tags = []
                if (Array.isArray(record.tags))
                    tags = record.tags.map((tag) => (
                        <Tag key={tag} color={stringToHSLNoYellow(tag)} style={{ fontSize: "10px", lineHeight: "10px", padding: "3px" }}>
                            {tag}
                        </Tag>
                    ))

                return <Space>{tags}</Space>
            },
        },
        {
            title: "Git",
            dataIndex: ["gitlink"],
            key: "gitlink",
            width: "70px",
            render: (text, record, index) => {
                return <GitLink gitlinkRecord={record.gitlink} />
            },
        },
        {
            title: "Updated",
            dataIndex: "updated_at",
            key: "updated_at",
            sort: true,
            search: true,
            width: "120px",
            render: (text, record, index) => {
                return <Text style={{ fontSize: "10px" }}>{text ? DateTime.fromISO(text).toLocaleString(DateTime.DATETIME_MED) : ""}</Text>
            },
        },
        {
            title: "Actions",
            key: "action",
            sort: true,
            width: "140px",
            render: (text, record, index) => (
                <Space size="small">
                    <XButton
                        tooltipConfig={{ title: "Schedule Flow" }}
                        modalConfig={{ Modal: ModalScheduleFlow, props: { flowId: record.id } }}
                        type="primary"
                        icon={<ClockCircleOutlined />}
                    ></XButton>
                    <XButton
                        tooltipConfig={{ title: "Delete Flow" }}
                        popConfirmConfig={{
                            title: `Are you sure you want to delete "${record.name}?"`,
                            onConfirm: () => {
                                handleDeleteFlow(record.id)
                            },
                        }}
                        type="primary"
                        icon={<DeleteOutlined />}
                    ></XButton>
                </Space>
            ),
        },
    ]

    return (
        <XTable
            size="small"
            columns={columns}
            loading={loading}
            dataSource={flows}
            filterReset={true}
            rowKey="id"
            pagination={{ pageSize: 20, size: "small", hideOnSinglePage: true }}
            rowClassName={(record, index) => {
                if (expandedFlows.includes(record.id)) return "expandedTableRow"
                return null
            }}
            expandable={{
                onExpandedRowsChange: (expandedRows) => {
                    setExpandedFlows(expandedRows)
                },
                expandedRowRender: (record, index, indent, expanded) => (
                    <>
                        <Title level={5} style={{ marginLeft: "40px" }}>
                            Flow Overview:
                        </Title>

                        <Row style={{ height: "100%", marginLeft: "40px", marginBottom: "10px" }}>
                            <AssemblyFlow flow={record} />
                        </Row>

                        <Title level={5} style={{ marginLeft: "40px" }}>
                            Last Runs for Flow:
                        </Title>
                        <Row style={{ marginBottom: "12px", marginTop: "14px" }}>
                            <FlowRunTable flowId={record.id} expanded={expanded} />
                        </Row>
                    </>
                ),
                expandIcon: ({ expanded, onExpand, record }) =>
                    expanded ? <MinusSquareFilled onClick={(e) => onExpand(record, e)} style={{ color: theme["primary-color-lavender"] }} /> : <PlusOutlined onClick={(e) => onExpand(record, e)} />,
            }}
        ></XTable>
    )
}

export default FlowTable
