import { Layout } from "antd";
import React from "react";

const { Footer } = Layout

export const XFooter = (props) => {
    return (
        <Footer>
            {props.children}
        </Footer>
    )
}
export default XFooter;