import React, { useRef, useState } from "react"
import { Modal, Row, Space, message } from "antd"
import { DeleteOutlined } from "@ant-design/icons"
import { useMutation, gql } from "@apollo/client"

import { XForm } from "../../../XForm"
import XButton from "../../../XButton"

const UPDATE_CRON = gql`
    mutation UpdateApiTrigger($id: Int!, $expression: String!) {
        update_trigger_by_pk(pk_columns: { id: $id }, _set: { expression: $expression }) {
            expression
            id
        }
    }
`

const DELETE_CRON = gql`
    mutation DeleteApiTrigger($id: Int!) {
        delete_trigger_by_pk(id: $id) {
            id
        }
    }
`

export const ModalEditCron = (props) => {
    const { open, onClose, onUpdate, cron, error } = props
    const [formData, setFormData] = useState(cron)
    const [confirmLoading, setConfirmLoading] = useState(false)
    const formRef = useRef(null)

    const [updateCron] = useMutation(UPDATE_CRON)
    const [deleteCron] = useMutation(DELETE_CRON)

    const cronSchema = {
        type: "object",
        $schema: "http://json-schema.org/draft-07/schema#",
        properties: {
            expression: {
                type: "string",
                subtype: "cron",
                description: "Type in the Cron Expression that should trigger this Schedule.",
            },
        },
        required: ["expression"],
    }

    if (error) {
        message.error(error.message)
        console.log(error)
    }

    const handleOk = (props) => {
        setConfirmLoading(true)

        const cronFormValid = formRef.current.validateForm()
        if (cronFormValid) {
            updateCron({
                variables: {
                    id: cron.id,
                    expression: formData.expression,
                },
            })
                .then(() => {
                    message.success("Updated successfully.")
                })
                .catch((error) => {
                    message.error(error.message)
                    console.log(error)
                })
                .finally(() => {
                    if (onUpdate) onUpdate()
                    onClose()
                })
        }

        setConfirmLoading(false)
    }

    const handleDeleteCron = (props) => {
        deleteCron({
            variables: {
                id: cron.id,
            },
        })
            .then(() => {
                message.success("Deleted successfully.")
            })
            .catch((error) => {
                message.error(error.message)
                console.log(error)
            })
            .finally(() => {
                if (onUpdate) onUpdate()
                onClose()
            })
    }

    return (
        <Modal title={"Edit Cron"} open={open} confirmLoading={confirmLoading} okText="Submit" onOk={handleOk} onCancel={onClose} width={800}>
            <Row style={{ width: "100%", marginBottom: "20px" }}>
                <XForm
                    ref={formRef}
                    style={{ marginBottom: "20px" }}
                    schema={cronSchema}
                    hideButton
                    // liveValidate={true}
                    formData={formData}
                    onChange={(e) => {
                        setFormData(e.formData)
                    }}
                ></XForm>
            </Row>
            <Row style={{ width: "100%", marginBottom: "20px" }}>
                <Space>
                    <XButton popConfirmConfig={{ title: "Are you sure you want to delete this Trigger?", onConfirm: handleDeleteCron }} icon={<DeleteOutlined />}>
                        Delete Cron
                    </XButton>
                </Space>
            </Row>
        </Modal>
    )
}

export default ModalEditCron
