import React, { useState } from "react"
import { Switch, Typography, Space, message } from "antd"
import { EditOutlined, DeleteOutlined, EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons"
import { useQuery, useMutation, gql } from "@apollo/client"
import { useInterval } from "usehooks-ts"

import { ModalSecretCreateEdit } from "./ModalSecretCreateEdit"
import XTable from "../../common/XTable"
import XButton from "../../common/XButton"

const { Text } = Typography

const GET_SECRETS = gql`
    query GetSecrets {
        secret {
            key
            value
            id
        }
    }
`

const DELETE_SECRET = gql`
    mutation DeleteFlow($id: uuid!) {
        delete_secret_by_pk(id: $id) {
            id
        }
    }
`

export const SecretTable = (props) => {
    const [modalOpen, setModalOpen] = useState(false)
    const [modalData, setModalData] = useState(null)
    const [showValues, setShowValues] = useState(false)
    const { loading, error, data, refetch } = useQuery(GET_SECRETS)
    const [deleteSecret] = useMutation(DELETE_SECRET)

    if (error) {
        message.error(error.message)
        console.log(error)
    }
    useInterval(() => {
        refetch().catch((error) => {
            message.error(error.message)
            console.log(error)
        })
    }, 60000)

    const secrets = data?.secret

    const handleDeleteSecret = (id) => {
        deleteSecret({ variables: { id: id } })
            .catch((error) => {
                message.error(error.message)
                console.log(error)
            })
            .then(() => {
                message.success("Secret deleted successfully.")
                refetch()
            })
    }

    const columns = [
        {
            title: "Key",
            dataIndex: "key",
            key: "key",
            sort: true,
            filter: true,
            search: true,
            render: (text, record, index) => {
                return <Text>{text}</Text>
            },
        },
        {
            title: "Value",
            dataIndex: ["value"],
            key: "value",
            render: (text, record, index) => {
                if (showValues) return <Text>{text}</Text>
                else return <Text>*****</Text>
            },
        },
        {
            title: "Actions",
            key: "action",
            sorter: true,
            render: (text, record, index) => (
                <Space size="small">
                    <XButton
                        tooltipConfig={{ title: "Edit Secret" }}
                        onClick={() => {
                            setModalData(record)
                            setModalOpen(true)
                        }}
                        type="primary"
                        icon={<EditOutlined />}
                    />
                    <XButton
                        tooltipConfig={{ title: "Delete Secret" }}
                        popConfirmConfig={{
                            title: `Are you sure you want to delete "${record.key}?"`,
                            onConfirm: () => {
                                handleDeleteSecret(record.id)
                            },
                        }}
                        type="primary"
                        icon={<DeleteOutlined />}
                    ></XButton>
                </Space>
            ),
        },
    ]

    return (
        <div>
            <Space style={{ width: "100%", marginBottom: "10px" }}>
                <XButton
                    type="primary"
                    onClick={() => {
                        setModalData(null)
                        setModalOpen(true)
                    }}
                >
                    Create Secret
                </XButton>

                <Switch
                    style={{ marginLeft: "10px" }}
                    checked={showValues}
                    checkedChildren={<EyeOutlined />}
                    unCheckedChildren={<EyeInvisibleOutlined />}
                    onChange={(checked, event) => {
                        setShowValues(checked)
                    }}
                />
            </Space>

            <XTable columns={columns} loading={loading} dataSource={secrets} rowKey="id"></XTable>

            <ModalSecretCreateEdit
                open={modalOpen}
                secret={modalData}
                onClose={() => {
                    setModalData(null)
                    setModalOpen(false)
                    refetch()
                }}
            />
        </div>
    )
}

export default SecretTable
