function downloadFile(fileUrl) {
    return new Promise((resolve, reject) => {
        fetch(fileUrl)
            .then((response) => {
                if (response.ok) {
                    return response.blob()
                } else {
                    throw new Error("Failed to download the file: " + response.statusText)
                }
            })
            .then((blob) => {
                // Extract the file name from the URL
                const urlPathname = new URL(fileUrl).pathname
                const fileName = urlPathname.substring(urlPathname.lastIndexOf("/") + 1)

                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    // For Internet Explorer
                    window.navigator.msSaveBlob(blob, fileName)
                } else {
                    // For other modern browsers
                    const url = window.URL.createObjectURL(blob)

                    // Create a temporary link element and perform the click action to download the file
                    const tempLink = document.createElement("a")
                    tempLink.style.display = "none"
                    tempLink.href = url
                    tempLink.setAttribute("download", fileName)
                    document.body.appendChild(tempLink)
                    tempLink.click()

                    // Clean up and revoke the object URL to release memory
                    document.body.removeChild(tempLink)
                    window.URL.revokeObjectURL(url)
                }

                resolve("File downloaded successfully")
            })
            .catch((error) => {
                reject("Error downloading the file: " + error)
            })
    })
}

export default downloadFile
