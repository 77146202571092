export const stringToRGB = function (str) {
    let hash = 0
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash)
    }
    let color = "#"
    for (let i = 0; i < 3; i++) {
        let value = (hash >> (i * 8)) & 0xff
        color += ("00" + value.toString(16)).substr(-2)
    }
    return color
}

export const stringToHSL = (string, saturation = 80, lightness = 60) => {
    let hash = 0
    for (let i = 0; i < string.length; i++) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash)
        hash = hash & hash
    }
    return `hsl(${hash % 360}, ${saturation}%, ${lightness}%)`
}

export const stringToHSLNoYellow = (string, saturation = 80, lightness = 60) => {
    let hash = 0
    for (let i = 0; i < string.length; i++) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash)
        hash = hash & hash
    }
    let hue = hash % 360
    if (hue > 45 && hue < 115) hue = hue + 70
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`
}

export default stringToRGB
