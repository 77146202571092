import React, { useState, useCallback } from "react"
import { Space, Spin, message } from "antd"
import { useQuery, useMutation, gql } from "@apollo/client"

import AddTriggerButton from "../FunctionButtons/AddTriggerButton"
import SearchAddFlowScheduleButton from "../FunctionButtons/SearchAddFlowScheduleButton"

import { XFlow } from "."

const GET_PROJECT_SCHEDULES = gql`
    query MyQuery {
        flow_schedule {
            name
            id
            parameters
            updated_at
            flow_id
            description
            created_at
            flow {
                name
                flow_settings
                aggregation_flow_settings
                project_id
                gitlink
            }
        }
        trigger {
            id
            type
            expression
            api
            api_token
            from_schedule_id
            last_trigger_time
            linked_trigger
            m2m_trigger_schedules {
                schedule_id
            }
        }
    }
`

const INSERT_M2M_TRIGGER_SCHEDULE = gql`
    mutation MyMutation($schedule_id: uuid!, $trigger_id: Int!) {
        insert_m2m_trigger_schedule_one(object: { schedule_id: $schedule_id, trigger_id: $trigger_id }) {
            id
        }
    }
`

const INSERT_M2M_CHAIN_SCHEDULE = gql`
    mutation MyMutation($source_schedule_id: uuid!, $target_schedule_id: uuid!) {
        insert_trigger_one(object: { from_schedule_id: $source_schedule_id, type: "CHAIN", m2m_trigger_schedules: { data: { schedule_id: $target_schedule_id } } }) {
            id
        }
    }
`

const DELETE_M2M_TRIGGER_SCHEDULE = gql`
    mutation MyMutation($schedule_id: uuid!, $trigger_id: Int!) {
        delete_m2m_trigger_schedule(where: { _and: { trigger_id: { _eq: $trigger_id }, schedule_id: { _eq: $schedule_id } } }) {
            affected_rows
        }
    }
`

const DELETE_M2M_CHAIN_SCHEDULE = gql`
    mutation MyMutation($source_schedule_id: uuid!, $target_schedule_id: uuid!) {
        delete_trigger(where: { from_schedule_id: { _eq: $source_schedule_id }, m2m_trigger_schedules: { schedule_id: { _eq: $target_schedule_id } } }) {
            affected_rows
        }
    }
`

export const NewProjectFlow = (props) => {
    const { onUpdate, ...otherProps } = props
    const [loading, setLoading] = useState(false)
    const { _loading, error, data, refetch } = useQuery(GET_PROJECT_SCHEDULES)

    const [insertM2MTriggerSchedule] = useMutation(INSERT_M2M_TRIGGER_SCHEDULE)
    const [insterM2MChainSchedule] = useMutation(INSERT_M2M_CHAIN_SCHEDULE)
    const [deleteM2MTriggerSchedule] = useMutation(DELETE_M2M_TRIGGER_SCHEDULE)
    const [deleteM2MChainSchedule] = useMutation(DELETE_M2M_CHAIN_SCHEDULE)

    const handleAction = (action, variables, successMessage) => {
        setLoading(true)
        action({ variables: variables })
            .then((response) => {
                refetch()
                message.success(successMessage)
            })
            .catch((error) => {
                message.error(error.message)
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const onConnect = ({ source, target, sourceHandle, targetHandle }) => {
        let [sourceType, sourceId] = source.split("_")
        let [targetType, targetId] = target.split("_")
        if (sourceType === "trigger" && targetType === "schedule") {
            handleAction(insertM2MTriggerSchedule, { trigger_id: sourceId, schedule_id: targetId }, "Added Trigger -> Schedule Connection.")
        } else if (sourceType === "schedule" && targetType === "schedule") {
            handleAction(insterM2MChainSchedule, { source_schedule_id: sourceId, target_schedule_id: targetId }, "Added Schedule -> Schedule Connection.")
        } else message.error("Invalid Connection.")
    }

    const onDisconnect = useCallback((edge) => {
        let [sourceType, sourceId] = edge.source.split("_")
        let [targetType, targetId] = edge.target.split("_")
        if (sourceType === "trigger" && targetType === "schedule") {
            handleAction(deleteM2MTriggerSchedule, { trigger_id: sourceId, schedule_id: targetId }, "Deleted Trigger -> Schedule Connection.")
        } else if (sourceType === "schedule" && targetType === "schedule") {
            handleAction(deleteM2MChainSchedule, { source_schedule_id: sourceId, target_schedule_id: targetId }, "Deleted Schedule -> Schedule Connection.")
        } else message.error("Invalid Connection.")
    }, [])

    if (_loading) return <Spin style={{ borderRadius: "10px" }} tip="Loading..." size="small"></Spin>
    if (error) {
        message.error(error.message)
        console.log(error)
    }
    if (!data) return <div>no data.</div>

    let nodes = []
    let edges = []

    const flowSchedules = data.flow_schedule
    const trigger = data.trigger

    flowSchedules.forEach((schedule) => {
        nodes.push({
            id: "schedule_" + schedule.id,
            name: schedule.name,
            type: "SCHEDULE",
            schedule: schedule,
            onUpdate: () => refetch(),
        })
    })

    trigger.forEach((t) => {
        if (t.type === "CHAIN") {
            t.m2m_trigger_schedules.forEach((m2m) => {
                edges.push({
                    source: "schedule_" + t.from_schedule_id,
                    target: "schedule_" + m2m.schedule_id,
                })
            })
        } else {
            nodes.push({
                id: "trigger_" + t.id.toString(),
                name: t.expression || t.api,
                type: t.type,
                trigger: t,
                onUpdate: () => refetch(),
            })
            if (Array.isArray(t.m2m_trigger_schedules)) {
                t.m2m_trigger_schedules.forEach((m2m) => {
                    edges.push({
                        source: "trigger_" + t.id.toString(),
                        target: "schedule_" + m2m.schedule_id,
                    })
                })
            }
        }
    })

    const menu = (
        <Space style={{ position: "absolute", top: "5px", left: "5px", padding: "2px" }}>
            <AddTriggerButton
                type="primary"
                shape="round"
                onChange={() => {
                    refetch()
                }}
            ></AddTriggerButton>
            <SearchAddFlowScheduleButton onChange={() => refetch()}></SearchAddFlowScheduleButton>
        </Space>
    )

    return <XFlow {...otherProps} nodes={nodes} edges={edges} menu={menu} onConnect={onConnect} snapToGrid onDisconnect={onDisconnect}></XFlow>
}

export default NewProjectFlow
