import React from "react"
import { message } from "antd"

import XButton from "../XButton"

export const XCopyClipboard = (props) => {
    const { icon, cpyBtnText, cpyColor, cpyBorderColor, cpyBackgColor, text, onClick } = props

    const handleCopyClipboard = (e) => {
        try {
            navigator.clipboard.writeText(text)
            message.success(`Copied successfully! FlowRunId: ${text}`)
        } catch (err) {
            message.error("Copy not successful!")
        }

        if (onClick) onClick(e)
    }

    const cpyStyle = {
        boxShadow: "none",
        width: "auto",
        color: cpyColor,
        borderColor: cpyBorderColor,
        backgroundColor: cpyBackgColor,
        marginLeft: "10px",
        marginRight: "10px",
    }

    return (
        <XButton icon={icon} onClick={handleCopyClipboard} style={cpyStyle}>
            {cpyBtnText}
        </XButton>
    )
}

export default XCopyClipboard
