import React, { useEffect, useState } from "react"
import { Form } from "antd"
import Editor from "@monaco-editor/react"
import { theme } from "../../../../style/antd/theme"
import { ExpandAltOutlined, ShrinkOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons"

export const JsonEdit = (props) => {
    const { formData } = props
    const [jsonData, setJsonData] = useState(JSON.stringify(formData, null, 2))
    const [jsonError, setJsonError] = useState(null)
    const [expandedJson, setExpandedJson] = useState(false)
    const [minimapEnabled, setMinimapEnabled] = useState(false)

    useEffect(() => {
        setJsonData(JSON.stringify(formData, null, 2))
    }, [formData])

    const handleChange = (newData) => {
        try {
            setJsonData(newData)
            props.onChange(JSON.parse(newData))
            setJsonError(null)
        } catch (error) {
            setJsonError(error.message)
        }
    }

    const calculateEditorHeight = () => {
        const lineCount = jsonData.split("\n").length

        if (expandedJson) {
            return lineCount * 19
        } else if (lineCount > 17) {
            return 15 * 19
        }
        return lineCount * 19
    }

    const handleExpandJson = (e) => {
        e.stopPropagation()
        setExpandedJson(!expandedJson)
    }

    const toggleMinimap = () => {
        setMinimapEnabled(!minimapEnabled)
    }

    const shouldShowExpandIcon = () => jsonData.split("\n").length > 17

    return (
        <Form.Item validateStatus={jsonError ? "error" : "success"} help={jsonError} style={{ marginBottom: "0px" }}>
            <div style={{ borderStyle: "solid", borderRadius: "2px", borderWidth: "1px", borderColor: "#D9D9D9", overflow: "hidden", position: "relative" }}>
                <Editor
                    options={{
                        automaticLayout: true,
                        scrollBeyondLastColumn: 0,
                        contextmenu: false,
                        readOnly: props.readonly || false,
                        formatOnType: true,
                        overviewRulerBorder: false,
                        overviewRulerLanes: 0,
                        scrollbar: { vertical: "hidden" },
                        minimap: {
                            enabled: minimapEnabled,
                            side: "left",
                        },
                    }}
                    height={`${calculateEditorHeight()}px`}
                    theme="light"
                    defaultLanguage="json"
                    value={jsonData}
                    onChange={handleChange}
                />
                <div style={{ position: "absolute", top: 0, right: 0, padding: "10px", zIndex: 1 }}>
                    {shouldShowExpandIcon() ? (
                        !expandedJson ? (
                            <ExpandAltOutlined style={{ color: theme["secondary-color"], fontSize: "17px" }} onClick={handleExpandJson} />
                        ) : (
                            <ShrinkOutlined style={{ color: theme["secondary-color"], fontSize: "18px" }} onClick={handleExpandJson} />
                        )
                    ) : null}
                    {!minimapEnabled ? (
                        <MenuUnfoldOutlined
                            style={{ color: theme["secondary-color"], fontSize: "18px" }}
                            onClick={() => {
                                toggleMinimap()
                            }}
                        />
                    ) : (
                        <MenuFoldOutlined
                            style={{ color: theme["secondary-color"], fontSize: "18px" }}
                            onClick={() => {
                                toggleMinimap()
                            }}
                        />
                    )}
                </div>
            </div>
        </Form.Item>
    )
}

export default JsonEdit
