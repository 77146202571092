import { Row, Breadcrumb } from "antd";
import React from "react";

export const ProjectsPage = (props) => {
    return (
        <Row style={{ maxWidth: "1200px", padding: "10px" }}>
            <Breadcrumb>
                <Breadcrumb.Item>
                    <a href="/">Home</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <a href="/projects">Projects</a>
                </Breadcrumb.Item>
            </Breadcrumb>


            <Row justify="center" style={{ width: "100%" }}>
                Projects Page
            </Row>
        </Row>
    )
}
export default ProjectsPage