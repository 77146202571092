import React, { useEffect, useState } from "react";
import { Input, Row, Col, Tooltip, Select, DatePicker } from 'antd';
import {
    QuestionCircleOutlined
} from '@ant-design/icons'
import moment from "moment-timezone";

import AssemblyFieldLayout from "./AssemblyFieldLayout";
const { Option } = Select;

const formats = [
    "YYYY-MM-DDTHH:mm:ssZ",
    "YYYY-MM-DDTHH:mm:ss.SSSZ",
    "YYYY-MM-DDTHH:mm:ss.SSSSSSZ",
    "YYYY-MM-DD"
];

function AssemblyRelativeDateTimeField(props) {
    const { formData, schema } = props

    const defaultMoment = moment(formData, formats, true)
    const [isFixed, setIsFixed] = useState(formData && defaultMoment.isValid())

    const [relativeValue, setRelativeValue] = useState(isFixed ? "" : formData)
    const [fixedValue, setFixedValue] = useState(defaultMoment.isValid() ? defaultMoment : moment())

    const fixedType = schema.anyOf[0]?.subtype ? schema.anyOf[0].subtype : (schema.anyOf[1]?.subtype ? schema.anyOf[1].subtype : "datetime")

    useEffect(() => {
        const newMoment = moment(formData, formats, true)
        const newInputType = formData && newMoment.isValid()

        if (newInputType) {
            setIsFixed(true)
            setFixedValue(newMoment)
        } else {
            setIsFixed(false)
            setRelativeValue(formData)
        }

    }, [formData])


    const handleSelectionChange = (value) => {
        setIsFixed(value === "fixed")
        if (value === "fixed") {
            const newVal = fixedType === "date" ? fixedValue.format('YYYY-MM-DD') : fixedValue.toISOString()
            props.onChange(newVal)
        } else {
            props.onChange(relativeValue)
        }
    }
    const handleRelativeChange = (e) => {
        try {
            setRelativeValue(e.target.value)
            props.onChange(e.target.value)
        } catch (error) {
            console.log(error)
        }
    }
    const handleFixedChange = (value) => {
        try {
            setFixedValue(value)
            if (value?.isValid()) {
                const newVal = fixedType === "date" ? value.format('YYYY-MM-DD') : value.toISOString()
                props.onChange(newVal)
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <AssemblyFieldLayout {...props}>
            <Row wrap={false}>
                <Col flex="110px">
                    <Select style={{ width: "98%" }} onChange={handleSelectionChange} disabled={props.disabled}
                        value={isFixed ? "fixed" : "relative"}>
                        <Option value="relative">Relative</Option>
                        <Option value="fixed">{fixedType === 'date' ? 'Date' : 'Datetime'}</Option>
                    </Select>
                </Col>
                <Col flex="auto">

                    {isFixed && fixedType === 'datetime' &&
                        <DatePicker style={{ width: "100%" }} showTime
                            format="YYYY-MM-DD HH:mm:ss" disabled={props.disabled}
                            defaultValue={fixedValue}
                            onChange={handleFixedChange} />}

                    {isFixed && fixedType === "date" &&
                        <DatePicker style={{ width: "100%" }} disabled={props.disabled}
                            defaultValue={fixedValue}
                            format="YYYY-MM-DD"
                            onChange={handleFixedChange} />}

                    {!isFixed &&
                        <Input prefix={props.disabled ? <QuestionCircleOutlined style={{ color: "lightgray" }} /> : <Tooltip placement="top" title="Enter an expression to describe a point of time relative to the model execution time, e.g. 'now', 'today at 6am', '2 days ago' etc.">
                            <QuestionCircleOutlined />
                        </Tooltip>} value={relativeValue} onChange={handleRelativeChange} disabled={props.disabled} />}

                </Col>
            </Row>
        </AssemblyFieldLayout>
    );
}

export default AssemblyRelativeDateTimeField;
