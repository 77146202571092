import { Modal, Row, message } from "antd"
import React, { useState, useRef, useEffect } from "react"
import { useMutation, gql } from "@apollo/client"

import { XForm } from "../../common/XForm"

const CREATE_EDIT_IDENTIFIER = gql`
    mutation CreateIdentifier($description: String = "", $identifier: String = "") {
        insert_resultstore_identifier_one(object: { description: $description, identifier: $identifier }, on_conflict: { constraint: identifier_pkey, update_columns: description }) {
            description
            identifier
        }
    }
`

export const ModalResultsCreateEdit = (props) => {
    const { open, onClose, identifier } = props
    const editMode = !!identifier
    const [createEditIdentifier] = useMutation(CREATE_EDIT_IDENTIFIER)
    const identifierFormRef = useRef(null)
    const [identifierFormData, setIdentifierFormData] = useState(identifier)
    const [confirmLoading, setConfirmLoading] = useState(false)

    console.log(props)

    useEffect(() => {
        setIdentifierFormData(identifier)
    }, [identifier])

    const identifierSchema = {
        type: "object",
        $schema: "http://json-schema.org/draft-07/schema#",
        properties: {
            identifier: {
                type: "string",
                description: "The name of the identifier.",
                pattern: "^[A-Z0-9_]+$",
                readOnly: editMode ? true : false,
            },
            description: {
                type: "string",
                description: "The description of the identifier.",
            },
        },
        required: ["identifier"],
    }

    const handleOk = () => {
        setConfirmLoading(true)

        const identifierFormValid = identifierFormRef.current.validateForm()

        if (identifierFormValid) {
            createEditIdentifier({
                variables: {
                    identifier: identifierFormData.identifier,
                    description: identifierFormData.description,
                },
            })
                .catch((error) => {
                    message.error(error.message)
                    console.log(error)
                })
                .then(() => {
                    const successMessage = editMode ? "edited" : "created"
                    message.success(`Identifier ${successMessage} successfully.`)
                    onClose()
                    resetForm()
                })
        }

        setConfirmLoading(false)
    }

    function inputErrors(errors) {
        console.log(errors)
        return errors.map((error) => {
            if (error.name === "pattern") {
                error.message = "only uppercase letters, numbers and underscores are allowed"
            }
            return error
        })
    }

    const handleIdentifierChange = (e) => {
        const { formData } = e
        if (formData.identifier) {
            formData.identifier = formData.identifier.toUpperCase()
        }
        setIdentifierFormData(formData)
    }
    const resetForm = () => {
        setIdentifierFormData({})
    }
    const handleCancel = () => {
        onClose()
        resetForm()
    }

    return (
        <Modal title={editMode ? "Edit Identifier" : "Create Identifier"} open={open} confirmLoading={confirmLoading} okText="Submit" onOk={handleOk} onCancel={handleCancel} width={800}>
            <Row style={{ width: "100%", marginBottom: "20px" }}>
                <XForm
                    ref={identifierFormRef}
                    style={{ marginBottom: "20px" }}
                    schema={identifierSchema}
                    hideButton
                    liveValidate={true}
                    formData={identifierFormData}
                    onChange={(e) => {
                        handleIdentifierChange(e)
                        setIdentifierFormData(e.formData)
                    }}
                    transformErrors={inputErrors}
                ></XForm>
            </Row>
        </Modal>
    )
}
