import React from "react"
import { Card, Spin } from "antd"

const InfoCard = ({ title, content, color, loading }) => {
    if (loading)
        return (
            <Card style={{ height: "80px", borderLeft: `6px solid ${color}` }}>
                <Spin style={{ borderRadius: "10px" }} tip="Loading..." size="small"></Spin>
            </Card>
        )
    return (
        <Card style={{ height: "80px", borderLeft: `6px solid ${color}` }}>
            <p style={{ margin: "0px", color: color }}>{title}</p>
            <p style={{ margin: "0px" }}>{content}</p>
        </Card>
    )
}

export default InfoCard
