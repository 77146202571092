import React from "react"
import { BrowserRouter as Router } from "react-router-dom"
import { Routes, Route } from "react-router-dom"
import { Layout, ConfigProvider } from "antd"

import "./App.less"

import ApolloConnection from "./services/apollo/ApolloConnection"

import TopBar from "./components/layout/TopBar"
import SideBar from "./components/layout/SideBar"
import XFooter from "./components/layout/Footer"

import LoginPage from "./components/pages/Authentication/LoginPage"
import NewUser from "./components/pages/Authentication/NewUser"
import HomePage from "./components/pages/HomePage"
import RunsPage from "./components/pages/RunsPage"
import SchedulesPage from "./components/pages/SchedulesPage"
import FlowsPage from "./components/pages/FlowsPage"
import ProjectsPage from "./components/pages/ProjectsPage"
import SecretsPage from "./components/pages/SecretsPage"
import UserManagementPage from "./components/pages/UserManagementPage"
import ResultsStorePage from "./components/pages/ResultsStorePage"
import ResultStoreData from "./components/pages/ResultsStorePage/ResultStoreData"

import DebugPage from "./components/pages/DebugPage"

import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, MsalProvider } from "@azure/msal-react"
import { loginRequest } from "./services/auth/msal/auth-config"

const { Content } = Layout

const WrappedView = () => {
    const { instance } = useMsal()
    const activeAccount = instance.getActiveAccount()
    const roles = activeAccount?.idTokenClaims?.roles

    const handleRedirect = () => {
        instance
            .loginRedirect({
                ...loginRequest,
                prompt: "create",
            })
            .catch((error) => console.log(error))
    }

    let canView = false
    if (Array.isArray(roles) && roles.includes("AssemblyAdmin")) {
        canView = true
    }

    return (
        <div className="App">
            <AuthenticatedTemplate>
                <ApolloConnection>
                    <Layout style={{ minHeight: "100vh" }}>
                        {canView && <SideBar></SideBar>}
                        <Layout>
                            <TopBar></TopBar>
                            <Content>
                                {canView && (
                                    <Routes>
                                        <Route exact path="/runs" element={<RunsPage />} />
                                        <Route exact path="/runs/:id" element={<RunsPage />} />

                                        <Route exact path="/schedules" element={<SchedulesPage />} />
                                        <Route exact path="/schedules/:id" element={<SchedulesPage />} />

                                        <Route exact path="/flows" element={<FlowsPage />} />
                                        <Route exact path="/flows/:id" element={<FlowsPage />} />

                                        <Route exact path="/resultsstore" element={<ResultsStorePage />} />
                                        <Route exact path="/resultsstore/:identifier" element={<ResultStoreData />} />

                                        <Route exact path="/projects" element={<ProjectsPage />} />
                                        <Route exact path="/secrets" element={<SecretsPage />} />
                                        <Route exact path="/users" element={<UserManagementPage />} />

                                        <Route exact path="/debug" element={<DebugPage />} />

                                        <Route exact path="/" element={<HomePage />} />
                                        <Route path="*" element={<p>Path not resolved</p>} />
                                    </Routes>
                                )}
                                {!canView && (
                                    <Routes>
                                        <Route path="*" element={<NewUser />} />
                                    </Routes>
                                )}
                            </Content>
                            <XFooter></XFooter>
                        </Layout>
                    </Layout>
                </ApolloConnection>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Routes>
                    <Route path="/login" element={<LoginPage login={handleRedirect} />} />
                    <Route exact path="*" element={<LoginPage login={handleRedirect} />} />
                </Routes>
            </UnauthenticatedTemplate>
        </div>
    )
}

const App = ({ instance }) => {
    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: "#031c90",
                    fontFamily: "Arial, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
                },
            }}
        >
            <MsalProvider instance={instance}>
                <Router>
                    <WrappedView></WrappedView>
                </Router>
            </MsalProvider>
        </ConfigProvider>
    )
}

export default App
