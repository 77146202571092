import React, { useCallback } from "react"
import Particles from "react-particles"
import { loadSlim } from "tsparticles-slim"

import { theme } from "../../../style/antd/theme"

export const IAVParticles = (props) => {
    const particlesInit = useCallback(async (engine) => {
        console.log(engine)
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        //await loadFull(engine);
        await loadSlim(engine)
    }, [])   

    const particlesLoaded = useCallback(async (container) => {
        await console.log(container)
    }, [])

    return (
        <Particles
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
                background: {
                    color: {
                        // value: theme["primary-color-blue-dark"],
                        value: "#f0f2f5",
                    },
                },
                fpsLimit: 120,
                particles: {
                    color: {
                        value: theme["primary-color-lavender"],
                    },
                    links: {
                        color: theme["primary-color-lavender"],
                        distance: 150,
                        enable: true,
                        opacity: 0.5,
                        width: 1,
                    },
                    move: {
                        direction: "none",
                        enable: true,
                        outModes: {
                            default: "bounce",
                        },
                        random: true,
                        speed: 2,
                        straight: false,
                    },
                    number: {
                        density: {
                            enable: true,
                            area: 500,
                        },
                        value: 120,
                    },
                    opacity: {
                        value: 1,
                    },
                    shape: {
                        type: "polygon",
                    },
                    size: {
                        value: { min: 1, max: 4 },
                    },
                },
                detectRetina: true,
            }}
        />
    )
}
