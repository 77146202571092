import React from "react"

import { Row, Card } from "antd"

import { IAVParticles } from "./IAVParticles"

export const NewUser = (props) => {
    return (
        <Row align="middle" justify="center" style={{ width: "100%", height: "100%", position: "relative" }}>
            <IAVParticles />
            <Card style={{ width: 600, top: "30vh", border: "1px solid black" }}>
                <p>Willkommen!</p>
                <p>Um Berechtigungen für diese Instanz zu bekommen, melde dich bitte bei: </p>
                <a href="mailto:alexander.mild@iav.de">alexander.mild@iav.de</a>
            </Card>
        </Row>
    )
}

export default NewUser
