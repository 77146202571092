import React from "react"
import { Spin } from "antd"
import { XFlow } from "."

const getNodesEdges = ({ flowSettings, taskRuns, showTaskStates, onUpdate }) => {
    if (!flowSettings)
        return {
            nodes: [],
            edges: [],
        }

    const tasks = flowSettings?.tasks || []
    const edges = flowSettings?.edges || []

    let parameters = []
    if (flowSettings?.parameter_schema?.properties) parameters = Object.keys(flowSettings?.parameter_schema?.properties)

    let secrets = []
    if (flowSettings?.secrets && Array.isArray(flowSettings.secrets)) secrets = flowSettings.secrets

    const flowNodes = tasks.map((task) => {
        let taskType = "TASK"
        if (parameters && parameters.includes(task)) taskType = "PARAMETER"
        else if (secrets.includes(task)) taskType = "SECRET"

        let taskRun = null
        if (taskRuns && Array.isArray(taskRuns)) {
            taskRun = taskRuns.find((tr) => tr.name === task)
        }

        return {
            id: task,
            name: task,
            type: taskType,
            task: task,
            taskRun: taskRun,
            showTaskState: showTaskStates,
            onUpdate: onUpdate,
        }
    })

    const flowEdges = edges.map((edge) => {
        return {
            source: edge.upstream_task,
            target: edge.downstream_task,
        }
    })

    return {
        nodes: flowNodes,
        edges: flowEdges,
    }
}

export const AssemblyFlow = (props) => {
    const { flow, taskRuns, showTaskStates, onUpdate, loading, ...otherProps } = props

    if (loading) {
        return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingLeft: "47%" }}>
                <Spin style={{ borderRadius: "10px" }} tip="Loading..." size="small"></Spin>
            </div>
        )
    } else if (!flow?.flow_settings) {
        return <div>No Flow Setting provided</div>
    }

    const flowData = getNodesEdges({ flowSettings: flow?.flow_settings, taskRuns: taskRuns, showTaskStates: showTaskStates, onUpdate: onUpdate })
    const aggregationFlowData = getNodesEdges({ flowSettings: flow?.aggregation_flow_settings, taskRuns: taskRuns, showTaskStates: showTaskStates, onUpdate: onUpdate })

    const nodes = [...flowData.nodes, ...aggregationFlowData.nodes]
    const edges = [...flowData.edges, ...aggregationFlowData.edges]

    console.log(nodes)
    console.log(edges)
    return <XFlow {...otherProps} nodes={nodes} edges={edges} loading={loading}></XFlow>
}

export default AssemblyFlow
