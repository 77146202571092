import React from "react"

function AssemblyObjectField(props) {
    return (
        <div>
            {props.properties.map((property) => {
                return property.content
            })}
        </div>
    )
}

export default AssemblyObjectField
