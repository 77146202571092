import React from "react"
import { useNavigate } from "react-router-dom"
import { Menu } from "antd"

import { ContactsOutlined, StarOutlined, DeploymentUnitOutlined, RocketOutlined, FireOutlined, AppstoreAddOutlined, SafetyCertificateOutlined, LockOutlined, DatabaseOutlined } from "@ant-design/icons"

export const SideMenu = (props) => {
    // const collapsed = props.collapsed

    let navigate = useNavigate()

    const handleClick = ({ item, key, keyPath, domEvent }) => {
        navigate("/" + key)
    }

    const menuItems = [
        {
            label: "Assembly Flows",
            key: "assembly",
            icon: <StarOutlined />,
            children: [
                { label: "Runs", key: "runs", icon: <RocketOutlined /> },
                { label: "Schedules", key: "schedules", icon: <DeploymentUnitOutlined /> },
                { label: "Flows", key: "flows", icon: <FireOutlined /> },
                { label: "Results Store", key: "resultsstore", icon: <DatabaseOutlined /> },
            ],
        },
        {
            label: "Administration",
            key: "administration",
            icon: <ContactsOutlined />,
            children: [
                { label: "Secrets", key: "secrets", icon: <LockOutlined /> },
                { label: "User", key: "users", icon: <SafetyCertificateOutlined /> },
                { label: "Projects", key: "projects", icon: <AppstoreAddOutlined /> },
                { label: "Debug", key: "debug", icon: <AppstoreAddOutlined /> },
            ],
        },
    ]

    return <Menu mode="inline" style={{ border: "0px" }} items={menuItems} onClick={handleClick} />
}
export default SideMenu
