import React, { useEffect, forwardRef, useState, useCallback, useRef } from "react"
import { Spin } from "antd"
import ReactFlow, { Background, Controls, ReactFlowProvider, applyNodeChanges, applyEdgeChanges } from "reactflow"
import dagre from "dagre"
import { getNodeIOType } from "./utils"

import TaskNode from "./CustomNodes/TaskNode"
import ParameterNode from "./CustomNodes/ParameterNode"
import SecretNode from "./CustomNodes/SecretNode"
import CronNode from "./CustomNodes/CronNode"
import ScheduleNode from "./CustomNodes/ScheduleNode"
import ApiNode from "./CustomNodes/ApiNode"
import nodeSizes from "./CustomNodes/nodeSizes"
import "reactflow/dist/style.css"
import "reactflow/dist/base.css"

const nodeTypes = {
    API: ApiNode,
    TASK: TaskNode,
    PARAMETER: ParameterNode,
    SECRET: SecretNode,
    CRON: CronNode,
    SCHEDULE: ScheduleNode,
}

const InnerXFlow = ({ nodes, edges, ...otherReactFlowProps }) => {
    const { onDisconnect, menu, ...otherProps } = otherReactFlowProps
    const [_nodes, setNodes] = useState(nodes)
    const [_edges, setEdges] = useState(edges)

    const edgeUpdateSuccessful = useRef(true)

    useEffect(() => {
        setNodes(nodes)
        setEdges(edges)
    }, [nodes, edges])

    const onNodesChange = useCallback((changes) => setNodes((nds) => applyNodeChanges(changes, nds)), [])
    const onEdgesChange = useCallback((changes) => setEdges((eds) => applyEdgeChanges(changes, eds)), [])

    const onEdgeUpdateStart = useCallback(() => {
        edgeUpdateSuccessful.current = false
    }, [])

    const onEdgeUpdate = useCallback((oldEdge, newConnection) => {
        edgeUpdateSuccessful.current = true
    }, [])

    const onEdgeUpdateEnd = useCallback((_, edge) => {
        if (!edgeUpdateSuccessful.current) {
            if (onDisconnect) onDisconnect(edge)
        }
        edgeUpdateSuccessful.current = true
    })

    return (
        <div style={{ height: "100%" }}>
            <ReactFlow
                fitView
                nodes={_nodes}
                edges={_edges}
                // edgeTypes={{'custom': CustomEdge}}
                nodeTypes={nodeTypes}
                proOptions={{ hideAttribution: true }}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onEdgeUpdateStart={onEdgeUpdateStart}
                onEdgeUpdate={onEdgeUpdate}
                onEdgeUpdateEnd={onEdgeUpdateEnd}
                // paneMoveable={locked ? false : true}
                zoomOnScroll={false}
                panOnDrag={true}
                // zoomOnScroll={_zoomOnScroll}
                preventScrolling={false}
                // onLoad={onLoad}
                {...otherProps}
            >
                <Controls />
                <Background variant="dots" gap={12} size={1} />
            </ReactFlow>
            <div style={{ position: "absolute", top: "5px", left: "5px", padding: "2px" }}>{menu}</div>
        </div>
    )
}

export const XFlow = forwardRef((props, ref) => {
    const { loading, nodes = [], edges = [], menu, ...otherReactFlowProps } = props

    const [flowNodes, setFlowNodes] = useState([])
    const [flowEdges, setFlowEdges] = useState([])

    useEffect(() => {
        // Create Elements
        let _nodes = nodes.map((node) => {
            const nodeIOType = getNodeIOType(node, edges)
            return {
                id: node.id,
                type: node.type,
                position: { x: 0, y: 0 },
                data: { ...node, _io_type: nodeIOType },
                selectable: true,
            }
        })

        let _edges = []
        edges.forEach((edge) => {
            const source = _nodes.find((node) => node.id === edge.source)
            const target = _nodes.find((node) => node.id === edge.target)
            if (source?.id && target?.id) {
                _edges.push({
                    id: `e_${source.id}_${target.id}`,
                    source: source.id,
                    target: target.id,
                })
            }
        })

        // Layout
        const dagreGraph = new dagre.graphlib.Graph()
        dagreGraph.setDefaultEdgeLabel(() => ({}))
        dagreGraph.setGraph({ rankdir: "LR" })

        _nodes.forEach((node) => {
            let nodeWidth = 150
            let nodeHeight = 50
            if (nodeSizes && nodeSizes.hasOwnProperty(node.type)) {
                nodeWidth = nodeSizes[node.type].nodeWidth
                nodeHeight = nodeSizes[node.type].nodeHeight
            }
            dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight })
        })

        _edges.forEach((edge) => {
            dagreGraph.setEdge(edge.source, edge.target)
        })

        dagre.layout(dagreGraph)
        // const maxUsedHeights = Object.values(dagreGraph._nodes).map(_n => _n.y + _n.height)
        // const maxUsedWidths = Object.values(dagreGraph._nodes).map(_n => _n.x + _n.width)
        // const maxUsedHeight = Math.max(...maxUsedHeights) + 40
        // const maxUsedWidth = Math.max(...maxUsedWidths) + 40

        const layoutedNodes = _nodes.map((node) => {
            const dagreNode = dagreGraph.node(node.id)
            return {
                ...node,
                targetPosition: "left",
                sourcePosition: "right",
                position: {
                    x: dagreNode.x - dagreNode.width / 2,
                    y: dagreNode.y - dagreNode.height / 2,
                },
            }
        })

        setFlowNodes(layoutedNodes)
        setFlowEdges(_edges)
    }, [nodes, edges])

    if (loading) return <Spin size="large" />
    // if (flowNodes.length === 0) return <div>No Elements in Data Flow.</div>

    const maxDimensions = flowNodes.reduce(
        (max, node) => ({
            x: Math.max(max.x, node.position.x),
            y: Math.max(max.y, node.position.y),
        }),
        { x: 0, y: 0 }
    )

    return (
        <div
            style={{
                backgroundColor: "white",
                width: "100%",
                minHeight: "200px",
                height: "100%",
                aspectRatio: `${maxDimensions.x} / ${maxDimensions.y + 60}`,
                padding: "1px",
                border: "1px solid #fef",
                position: "relative",
            }}
        >
            <ReactFlowProvider>
                <InnerXFlow nodes={flowNodes} edges={flowEdges} menu={menu} {...otherReactFlowProps}></InnerXFlow>
            </ReactFlowProvider>
        </div>
    )
})
