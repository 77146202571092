import React, { useState } from "react"
import { useParams } from "react-router-dom"
import { Typography, Space, message, DatePicker, Row, Breadcrumb } from "antd"
import { useQuery, useMutation, gql } from "@apollo/client"
import { useInterval } from "usehooks-ts"
import XTable from "../../common/XTable"
import HashPattern from "../../common/HashPattern"
import BlobDownloadButton from "../../common/FunctionButtons/BlobDownloadButton"
import { DateTime } from "luxon"
import moment from "moment"
import XButton from "../../common/XButton"
import { DeleteOutlined } from "@ant-design/icons"

const { RangePicker } = DatePicker

const { Text } = Typography

const GET_RESULTSTORE_DATA = gql`
    query GetResultStoreData($identifier: String, $startDate: timestamptz = "start", $endDate: timestamptz = "end") {
        resultstore_data(where: { identifier: { _eq: $identifier }, timestamp: { _gte: $startDate, _lte: $endDate } }, order_by: { timestamp: desc }) {
            created_at
            data_url
            id
            timestamp
            flow_run_id
            flow_schedule_id
            identifier
        }
    }
`

const DELETE_BLOB = gql`
    mutation DeleteMutation($blobId: uuid!) {
        deleteBlob(blobId: $blobId) {
            response
        }
    }
`

export const ResultStoreData = (props) => {
    const [filteredDates, setFilteredDates] = useState([moment(0), moment()])

    const { identifier } = useParams()
    const [deleteData, { error: deleteDataError }] = useMutation(DELETE_BLOB)
    const { loading, error, data, refetch } = useQuery(GET_RESULTSTORE_DATA, {
        variables: {
            identifier: useParams().identifier,
            startDate: filteredDates[0] ? moment(filteredDates[0]).startOf("day").toISOString() : null,
            endDate: filteredDates[1] ? moment(filteredDates[1]).endOf("day").toISOString() : null,
        },
    })

    let resultStoreData = data?.resultstore_data

    if (error) {
        message.error(error.message)
    }

    useInterval(() => {
        refetch().catch((error) => {
            message.error(error.message)
        })
    }, 60000)

    const handleDeleteData = (id) => {
        deleteData({ variables: { blobId: id } })
            .then(() => {
                if (deleteDataError !== undefined) {
                    message.error(`"${id}" delete failed!`)
                    console.log(deleteDataError)
                } else {
                    message.success(`"${id}" deleted successfully!`)
                    refetch()
                }
            })
            .catch((error) => {
                message.error(error.message)
                console.log(error)
            })
    }

    const handleDateChange = (dates, dateStrings) => {
        setFilteredDates(dates)
    }

    const columns = [
        {
            title: "ID",
            dataIndex: ["id"],
            key: "id",
            width: "70px",
            render: (text, record, index) => {
                return (
                    <Space style={{ marginLeft: "2px" }}>
                        <HashPattern tooltipConfig={{ title: record.id, width: "350px" }} text={text}></HashPattern>
                    </Space>
                )
            },
        },
        {
            title: "Name",
            dataIndex: "identifier",
            key: "identifier",
            render: (text, record, index) => {
                return <Text>{text}</Text>
            },
        },
        {
            title: "Created",
            dataIndex: "created_at",
            key: "created_at",
            sorter: true,
            render: (text, record, index) => {
                return <Text>{text ? DateTime.fromISO(text).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS) : ""}</Text>
            },
        },
        {
            title: "Timestamp",
            dataIndex: "timestamp",
            key: "timestamp",
            sorter: true,
            render: (text, record, index) => {
                return <Text>{text ? DateTime.fromISO(text).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS) : ""}</Text>
            },
        },
        {
            title: "Flow Run ID",
            dataIndex: "flow_run_id",
            key: "flow_run_id",
            width: "300px",
            sorter: true,
            search: true,
            render: (text, record, index) => {
                return <Text>{text}</Text>
            },
        },

        {
            title: "Actions",
            key: "action",
            width: "125px",
            render: (text, record, index) => (
                <Space size="small">
                    <XButton
                        tooltipConfig={{ title: "Delete Data" }}
                        popConfirmConfig={{
                            title: `Are you sure you want to delete "${record.id}?"`,
                            onConfirm: () => {
                                handleDeleteData(record.id)
                            },
                        }}
                        type="primary"
                        icon={<DeleteOutlined />}
                    ></XButton>
                    <BlobDownloadButton tooltipConfig={{ title: "Download" }} type="primary" blobLocation={record.data_url} />
                </Space>
            ),
        },
    ]
    return (
        <div>
            <Row style={{ width: "100%", padding: "10px", marginBottom: "20px" }}>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <a href="/">Home</a>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <a href="/resultsstore">Results Store</a>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <a href={`/resultsstore/${identifier}`}>{identifier}</a>
                    </Breadcrumb.Item>
                </Breadcrumb>
            </Row>

            <Row style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "30px" }}>
                <RangePicker
                    allowClear={false}
                    ranges={{
                        Today: [moment(), moment()],
                        "This Month": [moment().startOf("month"), moment().endOf("month")],
                    }}
                    value={filteredDates}
                    onChange={handleDateChange}
                />
            </Row>

            <XTable columns={columns} loading={loading} dataSource={resultStoreData} rowKey="id" style={{ margin: "15px", marginRight: "20px" }}></XTable>
        </div>
    )
}

export default ResultStoreData
