import React from "react"
import { message } from "antd"
import { DeleteOutlined } from "@ant-design/icons"
import { gql, useMutation } from "@apollo/client"

import XButton from "../../common/XButton"

const DELETE_FLOW_RUN = gql`
    mutation MyMutation($flowRunId: uuid = "") {
        delete_flow_run_by_pk(id: $flowRunId) {
            id
        }
    }
`

export const ButtonDeleteFlowRun = (props) => {
    const [deleteFlowRun] = useMutation(DELETE_FLOW_RUN)
    const id = props.id

    const handleDeleteRun = (flowRunId) => {
        deleteFlowRun({ variables: { flowRunId: flowRunId } })
            .then(() => {
                message.success("Flow run deleted successfully.")
            })
            .catch((error) => {
                console.error(`Error deleting flow run: ${error.message}`)
                message.error(error.message)
            })
    }

    return (
        <XButton
            tooltipConfig={{ title: "Delete FlowRun" }}
            popConfirmConfig={{
                title: `Are you sure you want to delete this FlowRun?`,
                onConfirm: () => {
                    handleDeleteRun(id)
                },
            }}
            type="primary"
            icon={<DeleteOutlined />}
        ></XButton>
    )
}

export default ButtonDeleteFlowRun
