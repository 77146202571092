exports.theme = {
    // Primary Colors
    "primary-color": "#031c90",
    "secondary-color": "#db46f2",

    success: "#52c41a",
    scheduled: "#F4C815",
    failed: "#FF0000",
    error: "#f5222d",
    warning: "#faad14",

    // Blue
    "primary-color-blue": "#031c90",
    "primary-color-blue-soft": "#dbddff",
    "primary-color-blue-light": "#040fff",
    "primary-color-blue-dark": "#0A2376",

    // Lavender
    "primary-color-lavender": "#db46f2",
    "primary-color-lavender-soft": "#F8DAFD",
    "primary-color-lavender-light": "#EA90F8",
    "primary-color-lavender-dark": "#7e288c",
    "primary-color-lavender-red": "#A8195E",

    // Gray
    "primary-color-gray": "#575358",
    "primary-color-gray-soft": "#d8ced7",
    "primary-color-gray-light": "#a594a1",
    "primary-color-gray-dark": "#363336",

    "layout-header-background": "#ffffff",
}
