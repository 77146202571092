import React from "react";

import AssemblyFieldLayout from "./AssemblyFieldLayout";


function AssemblyField(props) {
    return (
        <AssemblyFieldLayout {...props}>
            {props.children}
        </AssemblyFieldLayout>
    );
}

export default AssemblyField;