import React, { forwardRef, useRef, useImperativeHandle } from "react"
import { Button } from "antd"
import { withTheme } from "@rjsf/core"
import validator from "@rjsf/validator-ajv8"
import { Theme as AntDTheme } from "@rjsf/antd"

import schemaBuilder from "./schemaBuilder.js"
const RJSFForm = withTheme(AntDTheme)

export const XForm = forwardRef((props, ref) => {
    const { schema, uiSchema, readOnly, formData, onChange, onSubmit, onError, hideButton, style, ...formProps } = props
    const formRef = useRef(null)

    let _uiSchema = null
    if (uiSchema) _uiSchema = uiSchema
    else _uiSchema = schemaBuilder(schema, readOnly || false)

    const handleChange = (e) => {
        if (onChange) onChange(e)
    }

    const handleSubmit = (d) => {
        if (onSubmit) onSubmit(d.formdata)
    }

    const handleError = (e) => {
        if (onError) onError(e)
    }

    useImperativeHandle(ref, () => ({
        submit() {
            return formRef.current.submit()
        },
        validateForm() {
            return formRef.current.validateForm()
        },
    }))

    if (typeof schema === "object") {
        return (
            <RJSFForm
                ref={formRef}
                liveValidate={props.liveValidate || false}
                validator={validator}
                uiSchema={_uiSchema}
                schema={schema}
                formData={formData}
                onChange={handleChange}
                onSubmit={handleSubmit}
                onError={handleError}
                showErrorList={props.showErrorList || false}
                {...formProps}
            >
                <Button
                    onClick={() => {
                        formRef.current.submit()
                    }}
                    hidden={hideButton ? true : false}
                >
                    Submit
                </Button>
                {props.children}
            </RJSFForm>
        )
    }
    return <div>Form Error: Schema not of Type Object</div>
})
