import React, { useState } from "react"
import { Select, Tag } from "antd"

import { theme } from "../../../../style/antd/theme"
import AssemblyFieldLayout from "./AssemblyFieldLayout"

import { useInputOptions } from "../../../../utils/inputOptions"

export const AssemblyInputField = (props) => {
    const [value, setValue] = useState(props.formData)

    const inputResponse = useInputOptions()
    const rawInputOptions = inputResponse.inputOptions

    let inputOptions = []
    if (Array.isArray(rawInputOptions)) {
        inputOptions = rawInputOptions.map((item) => ({
            label: item.title,
            value: item.const,
        }))
    }

    const handleChange = (value) => {
        setValue(value)
        if (props.onChange) props.onChange(value)
    }

    function tagRender(tagprops) {
        const { label, closable, onClose } = tagprops
        const onPreventMouseDown = (event) => {
            event.preventDefault()
            event.stopPropagation()
        }
        return (
            <Tag color={props.schema?.tag_color || theme["primary-color-lavender"]} onMouseDown={onPreventMouseDown} closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
                {label}
            </Tag>
        )
    }

    return (
        <AssemblyFieldLayout {...props}>
            <Select allowClear style={{ width: "100%" }} value={value} onChange={handleChange} tagRender={tagRender} options={inputOptions}></Select>
        </AssemblyFieldLayout>
    )
}

export default AssemblyInputField
