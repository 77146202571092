import { Row, Typography } from "antd"
import React from "react"

import XNode from "../XNode"
import { getTaskStateBgColor } from "../nodeUtils"

const { Text } = Typography

const ParameterNode = (node) => {
    const showTaskState = node?.data?.showTaskState
    const taskRun = node?.data?.taskRun

    const effectColor = getTaskStateBgColor(showTaskState, taskRun?.state)
    const bgColor = "#aaa"

    return (
        <XNode node={node} tagText="Parameter" tagColor={effectColor} effectColor={effectColor} bgColor={bgColor}>
            <Row justify="center" wrap={false} style={{ height: "100%", padding: "0px" }}>
                <Text style={{ color: "white", margin: "2px" }}>{node?.data?.name}</Text>
            </Row>
        </XNode>
    )
}

export default ParameterNode
